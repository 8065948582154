import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  EventEmitter,
  inject,
  Injector,
  input,
  type OnInit,
  Output,
  signal
} from '@angular/core';
import {
  StudentAvailabilityCheckboxesComponent
} from '../../../student-availability-checkboxes/student-availability-checkboxes.component';
import {
  IAvailability,
  ICreateStudentGroupRequest,
  IFindCommonTimeSlotsResponse,
  IGetAvailabilityResponse,
  IStudentBasicInfoDto,
  IStudentGroupDto,
  IStudentLevelEnum,
  IWeekDayTimeSlotDto,
} from '@GeneratedTsFiles/index';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextareaModule } from 'primeng/textarea';
import { DropdownModule } from 'primeng/dropdown';
import { untilDestroyed } from 'src/app/core/helpers/until-destroyed';
import { StudentGroupService } from 'src/app/core/services/student-group.service';
import {
  AvailabilityDayTimePickerComponent
} from '../../../availability-day-time-picker/availability-day-time-picker.component';
import { AvailabilityPickerDaysComponent } from '../../../availability-picker-days/availability-picker-days.component';
import { ApiService } from 'src/app/core/services/api.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { DataApiStateService } from 'src/app/core/services/data-api-state.service';
import { GroupDialogState } from '../student-group-selection-dialog.component';
import { AuthService } from "../../../../../core/services/auth.service";
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
    selector: 'app-student-group-selection-availability-step',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextareaModule,
        DropdownModule,
        StudentAvailabilityCheckboxesComponent,
        AvailabilityDayTimePickerComponent,
        AvailabilityPickerDaysComponent,
    ],
    templateUrl: './student-group-selection-availability-step.component.html',
    styleUrl: './student-group-selection-availability-step.component.scss'
})
export class StudentGroupSelectionAvailabilityStepComponent implements OnInit {

  protected readonly GroupDialogState = GroupDialogState;
  formBuilder = inject(FormBuilder);
  studentGroupService = inject(StudentGroupService);
  dataApiStateService = inject(DataApiStateService);
  apiService = inject(ApiService);
  authService = inject(AuthService);
  generalService = inject(GeneralService);
  EditGroupStateEnum = GroupDialogState;
  times: { time: string, flag: any, checked: boolean, day: string }[] = [];
  selectedFlags: number | undefined | any = undefined;
  availabilityForm: FormGroup = new FormGroup({});
  IStudentLevelEnum = IStudentLevelEnum;
  levels = [
    { label: 'Select a Level *', value: null },
    { label: 'No Experience', value: IStudentLevelEnum.NoExperience },
    { label: 'Beginner', value: IStudentLevelEnum.Beginner },
    { label: 'Intermediate', value: IStudentLevelEnum.Intermediate },
    { label: 'Advanced', value: IStudentLevelEnum.Advanced }
  ];
  studentGroupItem = input.required<IStudentGroupDto>({});
  editGroupState = input<GroupDialogState>(GroupDialogState.None);
  availabilityData = signal([] as IWeekDayTimeSlotDto[]);
  availabilityDataLoaded = signal(false);
  students$ = computed(() => {
    return this.dataApiStateService.parentStudents.state() || [];
  });
  IStudentAvailabilityEnum = [];
  areAllCommonTimeSlotsEmptyBool = signal(true);
  user$ = computed(() => this.authService.userDecodedJWTData$());
  @Output() groupStateChanged: EventEmitter<any> = new EventEmitter();
  @Output() onAvailabilityStepValuesChanged: EventEmitter<Partial<ICreateStudentGroupRequest>> = new EventEmitter();
  @Output() onAvailabilityStepValidityChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() availabilityDetailsFormValid: EventEmitter<boolean> = new EventEmitter();
  private untilDestroyed = untilDestroyed();
  private injector = inject(Injector);
  invalidFields: string[] = [];

  ngOnInit(): void {
    if (this.editGroupState() === GroupDialogState.EditAvailability) {
      console.log(this.studentGroupItem());
      this.testGetAvailability(this.studentGroupItem().availabilityId!);
    } else {
      this.initializeForm();
      this.subscribeToFormChanges();
      this.subscribeToFindCommonTimeSlots();
    }
  }

  onSelectedFlagsChange(selectedFlags: number | undefined | any): void {
    console.log(selectedFlags);
    this.selectedFlags = selectedFlags;
    // Update the 'availability' field in the form based on selectedFlags
    this.availabilityForm.patchValue({
      availability: selectedFlags, // Update availability to the new selected flags
    });
  }

  fetchCommonTimeSlots(studentIds: string[]): void {
    this.apiService.findCommonTimeSlots({ studentIds }).pipe(this.untilDestroyed()).subscribe({
      next: (response: IFindCommonTimeSlotsResponse) => {
        console.log(response);
        this.availabilityDataLoaded.set(true);
        this.availabilityData.set(response.availability.weekDayTimeSlots);
        this.areAllCommonTimeSlotsEmptyBool.set(this.areAllCommonTimeSlotsEmpty(response.availability.weekDayTimeSlots));
      },
      error: (error: any) => {
        console.error('Error fetching common time slots:', error);
        // Handle error
        this.availabilityDataLoaded.set(true);
      },
    });
  }

  testGetAvailability(availabilityId: string): void {
    this.apiService.getApiData<IGetAvailabilityResponse>(
      { url: IAvailability.getAvailability, method: 'GET' },
      { availabilityId }).pipe(this.untilDestroyed()).subscribe({
        next: (response: IGetAvailabilityResponse) => {
          this.availabilityDataLoaded.set(true);
          this.availabilityData.set(response.availability.weekDayTimeSlots);
        },
        error: (err) => {
          this.availabilityDataLoaded.set(true);
        }
      });
  }

  areAllCommonTimeSlotsEmpty(data: IWeekDayTimeSlotDto[]): boolean {
    return data.every(item => item.timeSlots.length === 0);
  }

  onTimeSlotsChange(timeSlots: IWeekDayTimeSlotDto[]): void {
    console.log(timeSlots);
    this.studentGroupService.updateStudentGroupRequest({
      ...this.studentGroupService.getStudentGroupRequest(),
      weekDayTimeSlots: timeSlots,
    });
  }

  private initializeForm(): void {

    this.availabilityForm = this.formBuilder.group<Partial<any>>({
      moreDetails: [undefined, [Validators.required, Validators.maxLength(1500)]],
      studentLevel: [undefined, [Validators.required]],
    });
  }

  private buildStudentMoredetailsForGroup(students: IStudentBasicInfoDto[]): void {

    // Create the formatted string
    const studentDetails = students.map(student => {
      const firstName = student.firstName;
      const lastName = student.lastName;
      const moreDetails = student.studentTeachingLanguages![0]?.moreDetails || "No details provided";

      return `${firstName} ${lastName} - ${moreDetails}`;
    }).join('\n');

    // Use the string in your Angular reactive form
    this.availabilityForm.patchValue({
      moreDetails: studentDetails
    });
  }

  private subscribeToFindCommonTimeSlots(): void {
    const studentIdSubscription = toObservable(this.students$, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (user: any) => {
        if (user.data && user.data.students) {
          // TODO: check if this is needed
          // this.buildStudentMoredetailsForGroup(user.data.students);
          this.fetchCommonTimeSlots(this.studentGroupService.getStudentGroupRequest().studentsToAdd);

        }
      }

    });
  }

  private subscribeToFormChanges(): void {
    this.availabilityForm.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe((values) => {
        console.log('Form Values Changed:', values);
      });

    this.availabilityForm.statusChanges
      .pipe(this.untilDestroyed())
      .subscribe((status) => {
        console.log('Form Status Changed:', status);
        this.invalidFields = this.generalService.findInvalidControls(this.availabilityForm);
        this.availabilityDetailsFormValid.emit((status === 'VALID'));
        if (status === 'INVALID') {
          // Handle invalid form status
          console.error('Form is invalid');
        } else {
          // Handle valid form status
          console.log('Form is valid');
          this.handleValidForm();
        }
        this.onAvailabilityStepValidityChanged.emit(status === 'VALID');
      });
  }

  // Method to handle actions when the form is valid
  private handleValidForm(): void {
    // Implement your logic here
    this.studentGroupService.updateStudentGroupRequest({
      ...this.studentGroupService.getStudentGroupRequest(),
        teachingLanguageId: this.studentGroupService.getStudentGroupRequest().teachingLanguageId,
        studentLevel: this.availabilityForm.value.studentLevel,
        moreDetails: this.availabilityForm.value.moreDetails
    });
    this.onAvailabilityStepValuesChanged.emit(this.availabilityForm.value);
  }

  onAvailabilityDaysFormValid($event: boolean) {
    this.onAvailabilityStepValidityChanged.emit($event);
  }
}
