import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Output, type OnInit} from '@angular/core';

@Component({
    selector: 'app-student-group-selection-suggestion-text-step',
    imports: [
        CommonModule,
    ],
    templateUrl: './student-group-selection-suggestion-text-step.component.html',
    styleUrl: './student-group-selection-suggestion-text-step.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentGroupSelectionSuggestionTextStepComponent implements OnInit {

  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  onItemClicked(): void {
    this.buttonClicked.emit();
  }
}
