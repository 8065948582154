import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

@Component({
    selector: 'app-notification-item-card',
    imports: [
        CommonModule,
        ButtonModule,
        MenuModule,
    ],
    templateUrl: './notification-item-card.component.html',
    styleUrl: './notification-item-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemCardComponent {
  @Input() notification: any;
  @Input() type: any;
  @Input() isRead: any;

  items: MenuItem[] = [
    { label: 'Mark as read', icon: 'pi pi-fw pi-check' },
    { label: 'Delete', icon: 'pi pi-fw  pi-trash' },
  ];
  ngOnInit() {
    console.log(this.notification);
  }
}
