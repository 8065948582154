<!-- Show error if total duration is less than 60 minutes -->
<div *ngIf="timeSlotForm.errors && timeSlotForm.errors['totalDurationTooShort']">
  <span class="error-text">You must have at least one hour of total timeslot range across all selected days.</span>
</div>

@if (user()) {
  <div class="flex align-items-start mb-2">
    <div class="bg-gray-100 border-round inline-flex align-items-center justify-content-center w-3rem h-3rem">
      <img src="/assets/images/dashboard/time-run-1.png" class="w-2rem"/>
    </div>
    <div class="ml-3 flex-1"><span class="block text-900 mb-1 text-lg font-medium">Your Timezone </span>
      <p class="text-600 mt-0 text-sm"> {{ user()!.timeZoneDisplayName }}</p>
      <p class="text-600 mt-0 text-sm">{{localTime()}}</p>
    </div>
  </div>
  
}



<h5 class="primary-purple-color font-medium text-lg m-0 my-2">Which days are your Students available?</h5>
<div class="flex flex-wrap align-items-start justify-content-start gap-3 mb-3">
  <p-toggleButton (onChange)="toggleDay(day.value, i)" *ngFor="let day of daysOfWeek; let i = index"
                  [(ngModel)]="selectedDaysToggle[day.value]" [offLabel]="day.label" [onIcon]="'pi pi-check'" [onLabel]="day.label"
                  styleClass="text-sm">
  </p-toggleButton>
</div>


<div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-2 border-1 mb-3
bg-blue-50 border-blue-500" style="border-radius: 10px;">
  <div>
    <p class="text-blue-700 text-xs mt-0 mb-0">Select the time frame during which your student is available for lessons
      in your time zone, and we will match you with a teacher who fits your availability.</p>
  </div>
</div>


<form [formGroup]="timeSlotForm">
  <div formArrayName="timeSlots">
    <div *ngFor="let day of getTimeSlotsArray().controls; let i = index" [formGroupName]="i" class="">
      <ng-container *ngIf="daysOfWeek[i] && selectedDaysToggle[daysOfWeek[i].value]">
      <div *ngIf="selectedDaysToggle[daysOfWeek[i].value]" class="shadow-2 surface-card p-2 mt-2 border-round ">
        <h5 class="primary-purple-color font-medium text-lg m-0 my-2">{{ daysOfWeek[i].label }}</h5>
        <div formArrayName="timeSlots">
          <div
            *ngFor="let timeSlot of this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).controls; let j = index"
            [formGroupName]="j">
            <div class="flex flex-row align-items-center mb-2 gap-2">
              <div>
                <label class="font-xs text-500"> Start Time </label>
                <app-availability-time-picker-selector (timeChange)="onTimeSlotsChange($event, i, j)"
                                                       [control]="timeSlot.get('startTime')" [inputId]="'startTime-' + i + '-' + j"
                                                       [isStartTime]="true">
                </app-availability-time-picker-selector>
              </div>
              <div class="w-2rem flex flex-column">
                <label> &nbsp;</label>
                <div>&nbsp; - &nbsp;</div>
              </div>

              <div>
                <label class="font-xs text-500"> End Time </label>
                <app-availability-time-picker-selector (timeChange)="onTimeSlotsChange($event, i, j)"
                                                       [control]="timeSlot.get('endTime')" [inputId]="'endTime-' + i + '-' + j"
                                                       [isStartTime]="false">
                </app-availability-time-picker-selector>
              </div>

              <div class="md:ml-2 flex flex-column align-items-center justify-content-between">
                <label> &nbsp;</label>
                <div class=" flex flex-row align-items-center gap-1">
                  <i (click)="removeTimeSlot(i, j)" class="pi pi-trash remove-timeslot-button  p-0"></i>

                </div>
              </div>
            </div>

            <div class="flex flex-row align-items-center mb-2 gap-3">
              <!-- Add Time Slot button -->
              <p-button (click)="addTimeSlot(i)"
                        *ngIf="j === this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length - 1"
                        [outlined]="true" [rounded]="true" icon="pi pi-plus" icon="pi pi-plus" label="Add Time Frame"
                        size="small" styleClass="primary-purple-color p-2 text-xs flex"/>


              <p-button (click)="toggleCopyIndex(i)"
                        *ngIf="this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length !== 0 && !timeSlotForm.errors
&& j === this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length - 1"
                        [icon]="showCopyContentIndex !== -1 ? 'pi pi-chevron-up' : 'pi pi-chevron-down' " [outlined]="true" [rounded]="true" [text]="true"
                        label="Copy"
                        size="small" styleClass="border-blue-500 p-2 text-xs"/>

            </div>

            <div *ngIf="timeSlot.errors && timeSlot.errors['overlap']" class="mt-3 flex flex-column align-items-start justify-content-start p-2 border-1 border-round mb-3
              bg-red-50 border-red-500">
              <span class="error-text text-red-600">Time slots cannot overlap for this day.</span>

              <ul class="list-none p-0 m-0">
                <li *ngFor="let slot of timeSlot.errors['conflictingSlots']" class="text-sm">
                  Start time: {{ slot.start | date:'HH:mm' }} - End time: {{ slot.end | date:'HH:mm' }}
                </li>
              </ul>
            </div>

            <!-- Show validation error if endTime is before startTime -->
            <div *ngIf="timeSlot.errors && timeSlot.errors['maxDuration']" class="text-red-600">
              <span class="error-text">End time must be after start time.</span>
            </div>

            <!-- Show validation error if endTime is before startTime -->
            <div *ngIf="timeSlot.errors && timeSlot.errors['endTimeBeforeStartTime']" class="text-red-600">
              <span class="error-text">End time must be after start time.</span>
            </div>


            <!-- Show error if time slots are out of order -->
            <div *ngIf="timeSlot.errors &&  timeSlot.errors['outOfOrderTimeSlots']" class="text-red-600">
              <span class="error-text">Time slots must follow a chronological order.</span>
            </div>

            <!-- Show error if time slots overlap -->
            <div *ngIf="timeSlot.errors && timeSlot.errors['overlappingTimeSlots']" class="text-red-600">
              <span class="error-text">Time slots cannot overlap for this day.</span>
            </div>
          </div>
        </div>

        <div class="flex flex-row align-items-center mb-2 gap-3">
          <!-- If no time slots, show the 'Add Time Slot' button -->


          <p-button (click)="addTimeSlot(i)"
                    *ngIf="this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length === 0"
                    [icon]="'pi pi-plus'"
                    [outlined]="true" [rounded]="true" iconPos="left" label="Add Time Frame"
                    size="small" styleClass="purple-border primary-purple-color p-2 text-xs"/>


          <!-- <p-button *ngIf="this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length !== 0"
            label="Reset" (click)="clearTimeSlotsForDay(i)" [outlined]="true" [rounded]="true"
            icon="pi pi-calendar-minus" size="small" severity="danger" styleClass=" p-2 text-xs" /> -->

        </div>

        @if (showCopyContentIndex === i && this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, i).length
        !== 0) {

          <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
            <div class="p-2">
              <div class="flex align-items-center"><span
                class="inline-flex border-circle align-items-center justify-content-center bg-yellow-100 mr-2"
                style="width: 32px; height: 32px;"><i class="pi pi-calendar text-base text-yellow-600"></i></span><span
                class="text-900 font-medium text-lg">Copy {{ daysOfWeek[i].label }}'s Timeslots</span></div>
              <p class="mt-0 mb-3 text-700 text-xs line-height-3">
                Select the days you want to copy time slots from. The time slots will be copied to the selected days.
                You only choose from the selected days.
              </p>

              <li
                class="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-2 border-1 mb-3 bg-yellow-50 border-yellow-500"
                style="border-radius: 10px;">
                <div>
                  <div><span class="text-yellow-700 font-bold">Notice</span></div>
                  <p class="text-yellow-700 text-xs mt-2 mb-0">If you choose to copy time slots, <b>any existing time
                    slots for
                    the selected days will be deleted.</b></p>
                </div>
              </li>

              <div class="card flex align-items-center justify-content-start">
                <p-multiSelect [appendTo]="'body'" [options]="filterAvailableDays(i)" optionLabel="label"
                               placeholder="Select Days"
                               [showToggleAll]="true" class="w-full" styleClass="w-full"
                               (onChange)="onDaySelect($event.value)"/>
              </div>
            </div>
            <div class="px-4 py-3 surface-100 text-right">
              <p-button label="Copy to Selected Days" [disabled]="getSelectedDaysLength() === 0"
                        (click)="copyTimeSlotsToSelectedDays(i)" [outlined]="true" [rounded]="true"
                        [icon]="'pi pi-clone'"
                        iconPos="right" size="small" styleClass="purple-border primary-purple-color"/>
            </div>
          </div>


        }
        <hr class="w-full my-3 border-indigo-100 border-top-1"/>

      </div>
      </ng-container>
    </div>
  </div>
</form>

<button (click)="submit()" class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component cancel-bg-btn">

</button>