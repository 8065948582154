import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input, type OnInit} from '@angular/core';
import {StudentGroupRowDisplayComponent} from '../../../student-group-row-display/student-group-row-display.component';
import {IStudentGroupDto} from '@index';
import {GeneralService} from 'src/app/core/services/general.service';

@Component({
    selector: 'app-student-group-selection-success-action-step',
    imports: [
        CommonModule,
        StudentGroupRowDisplayComponent
    ],
    templateUrl: './student-group-selection-success-action-step.component.html',
    styleUrl: './student-group-selection-success-action-step.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentGroupSelectionSuccessActionStepComponent implements OnInit {

  // Injected services
  generalService = inject(GeneralService);

  studentGroupItem = input.required<IStudentGroupDto>({});

  ngOnInit(): void {
  }

}
