@if (items) {
  <div class="relative w-full">
    <p-carousel [value]="items" [numVisible]="this.numVisible" [numScroll]="this.numVisible"
                [responsiveOptions]="responsiveOptions" [showIndicators]="false"
                [showNavigators]="items.length > numVisible"
                [page]="carouselIndex" (onPage)="onCarouselIndexChange($event)" [circular]="false">
      <ng-template pTemplate="item" let-item let-index="index">

        <!-- <app-image-box-group-item [item]="item" [selectable]="selectable" [isSelected]="isSelected(item)"
        [isDisabled]="isDisabled" [imageProperty]="imageProperty" [languagesMode]="languagesMode"
        [prepareNameProperty]="prepareNameProperty">
    </app-image-box-group-item>
     -->
     <div class="grid">
      <div class="col-12">
        <div class="cursor-pointer text-center primary-purple-color m-auto
                    w-full align-items-center" (click)="selectable ? toggleSelection(item): null">

          <div [ngClass]="{'selected': isSelected(item),
                    'h-5rem': !imageProperty,
                    'disabled': isDisabled(item)}"
               class="{{selectable ? 'group-item border-1' : ''}} mx-1  p-2 py-3 border-round flex flex-column align-items-center justify-content-center relative">
            <p-checkbox *ngIf="selectionMode === 'multiple' && selectable" [binary]="true"
                        [ngModel]="isSelected(item)"
                        binary="true" styleClass="primary-availability-dropdown outlined absolute pointer-events-none">
            </p-checkbox>
            <ng-container *ngIf="!studentGroupMode">
              @if (languagesMode) {
                <img [src]="generalService.getImageUrlForLanguage(item.name)"
                     [ngClass]="{'filter-brightness': isSelected(item) && item.new}" alt="img"
                     class="w-2rem  pb-2"/>
              } @else {
                <img *ngIf="imageProperty" [src]="'assets/images/dummy/astronaut-01.png'"
                     [ngClass]="{'filter-brightness': isSelected(item) && item.new}" alt="img"
                     class="w-2rem  pb-2"/>
                <img *ngIf="itemImage" [src]="'assets/images/dummy/astronaut-01.png'"
                     [ngClass]="{'filter-brightness': isSelected(item) && item.new}" alt="img"
                     class="w-2rem  pb-2"/>
              }

            </ng-container>


            <ng-container *ngIf="studentGroupMode">
              <div class="flex relative">
                <!-- <ng-container *ngFor="let student of items; let i = index;">
                    @if (i < 3) {
                    <img *ngIf="imageProperty" [src]="'assets/images/dummy/astronaut-01.png'"
                    [ngClass]="{'filter-brightness': isSelected(student) && student.new,
                    'middle-group-img': i === 1}"
                    alt="img"
                        class="w-2rem pb-2" />
                    }
                </ng-container> -->
              </div>
            </ng-container>

            <p class="m-0 text-center capitalize">{{ prepareNameProperty(item) }}</p>

            <ng-container *ngIf="studentGroupMode">
              <app-student-group-row-display [studentGroupItem]="item"></app-student-group-row-display>
            </ng-container>
          </div>
        </div>
      </div>
      </div>
      </ng-template>
      <ng-template pTemplate="nexticon">
        <i class="pi pi-chevron-right next-a"></i>
      </ng-template>
    </p-carousel>
  </div>
}
