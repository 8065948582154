import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Output, inject, input, type OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {GeneralService} from 'src/app/core/services/general.service';
import {
  StudentGroupSelectionDialogComponent
} from '../../dialogs/student-group-selection-dialog/student-group-selection-dialog.component';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {StudentGroupRowDisplayComponent} from '../../student-group-row-display/student-group-row-display.component';

@Component({
    selector: 'app-sidebar-main-menu-list-item',
    imports: [
        CommonModule,
        ButtonModule,
        RouterModule,
        StudentGroupRowDisplayComponent
    ],
    templateUrl: './sidebar-main-menu-list-item.component.html',
    styleUrl: './sidebar-main-menu-list-item.component.scss'
})
export class SidebarMainMenuListItemComponent implements OnInit {
  generalService = inject(GeneralService);
  eventBusService = inject(EventBusService);
  router = inject(Router);
  item = input({} as any);
  link = input('');
  showEditButton = input(false);
  baseProperty = input('');
  nameProperty = input('name');
  textForNameProperty = input('');
  imageProperty = input('image');
  defaultItemImage = input('');
  isGroup = input(false);
  @Output() useButtonSelected = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<{ item: any; link: string; }>({} as any);

  constructor() {
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.showEditButton()) {

        // this.editButtonSelected();
      }
    }, 50);
  }

  editButtonSelected() {
    const params = {
      'editMode': true,
      'studentGroupItem': this.item(),
    }
    this.generalService.openComponent(StudentGroupSelectionDialogComponent, params);
  }

  onUseButtonSelected() {
    this.useButtonSelected.emit({
      'item': this.item(),
      'link': this.link()
    });
  }

  clicked() {
    console.log('clicked');
    // this.router.navigate([this.link()]);
    this.itemSelected.emit({
      'item': this.item(),
      'link': this.link()
    });
    this.eventBusService.emit(new EmitEvent(Events.NavigateParentStudentOverview, undefined));
  }

  prepareNameProperty() {
    if (this.baseProperty()) {
      const baseProperty = this.baseProperty() as string;
      return this.item()[baseProperty][this.nameProperty()] + this.textForNameProperty();
    } else {
      if (this.item()[this.nameProperty()]) {
        return this.item()[this.nameProperty()] + this.textForNameProperty();
      } else {
        return this.textForNameProperty();
      }
    }
  }
}
