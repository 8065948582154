import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Injector,
  input,
  Input,
  model,
  Output,
  signal
} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {
  IStudentGroupDto,
  ITeachingLanguageDto,
  IStudentBasicInfoDto,
  ILanguageLevelsEnum,
  ICreateStudentGroupResponse,
  IEditStudentGroupResponse,
  StudentGroupRoutes,
  IApiResponseBase,
  IGetStudentGroupResponse,
  ICreateStudentGroupRequest,
  IEditStudentGroupRequest,
  IStudentLevelEnum
} from '@GeneratedTsFiles/index';
import {Subscription, Observable} from 'rxjs';
import {untilDestroyed} from '../../../../../core/helpers/until-destroyed';
import {Severity} from '../../../../../core/models/severity';
import {ApiService} from '../../../../../core/services/api.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {CarouselService} from '../../../../../core/services/carousel.service';
import {DataApiStateService, State} from '../../../../../core/services/data-api-state.service';
import {EventBusService, EmitEvent, Events} from '../../../../../core/services/event-bus.service';
import {GeneralService} from '../../../../../core/services/general.service';
import {StudentGroupService, StoudentGroupRuleMessage} from '../../../../../core/services/student-group.service';
import {ToastService} from '../../../../../core/services/toast.service';
import {UserService} from '../../../../../core/services/user.service';
import {ToastMessages} from '../../../../models/toast-messages';
import {GroupDialogState} from '../student-group-selection-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import {CarouselModule} from 'primeng/carousel';
import {DropdownModule} from 'primeng/dropdown';
import {CustomDialogPopupComponent} from '../../../custom-dialog-popup/custom-dialog-popup.component';
import {
  ImageBoxGroupItemComponent
} from '../../../image-box-group-select/image-box-group-item/image-box-group-item.component';
import {ImageBoxGroupSelectComponent} from '../../../image-box-group-select/image-box-group-select.component';
import {
  FormFieldValidationMessageComponent
} from '../../../prime/form-field-validation-message/form-field-validation-message.component';
import {
  StudentAvailabilityCheckboxesComponent
} from '../../../student-availability-checkboxes/student-availability-checkboxes.component';
import {
  StudentGroupSelectionSuggestionTextStepComponent
} from '../student-group-selection-suggestion-text-step/student-group-selection-suggestion-text-step.component';

@Component({
    selector: 'app-student-group-selection-members-step',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        ButtonModule,
        AccordionModule,
        CarouselModule,
        ImageBoxGroupSelectComponent,
        CustomDialogPopupComponent,
        FormFieldValidationMessageComponent,
        ImageBoxGroupItemComponent,
        StudentGroupSelectionSuggestionTextStepComponent,
        StudentAvailabilityCheckboxesComponent,
        StudentGroupSelectionMembersStepComponent,
    ],
    templateUrl: './student-group-selection-members-step.component.html',
    styleUrl: './student-group-selection-members-step.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentGroupSelectionMembersStepComponent {

  @Output() groupStateChanged: EventEmitter<GroupDialogState> = new EventEmitter();
  @Output() groupItemChanged: EventEmitter<IStudentGroupDto> = new EventEmitter();
  @Output() onMembersStepSubmitted: EventEmitter<Partial<ICreateStudentGroupRequest>> = new EventEmitter();

  // Define signals
  readonly EditGroupState = GroupDialogState;
  editMode = input(false);
  step = signal(1);
  studentGroupItem = signal({} as IStudentGroupDto);
  editMembersMode = signal(false);
  editGroupState = input(this.EditGroupState.None);
  canEditGroup = signal(false);

  // Injected services
  generalService = inject(GeneralService);
  authService = inject(AuthService);
  userService = inject(UserService);
  apiService = inject(ApiService);
  studentGroupService = inject(StudentGroupService);
  dataStateService = inject(DataApiStateService);
  toastService = inject(ToastService);
  eventBusService = inject(EventBusService);
  carouselService = inject(CarouselService);

  // Computed signals
  user = computed(() => {
    console.log(this.#userToSignal());
    return this.#userToSignal();
  });
  students$ = computed(() => {
    const students = this.dataStateService.parentStudents.state()?.data?.students || [];
    const selectedLanguage = this.selectedTeachingLanguage$();
    const selectedLevel = this.selectedLanguageLevel$();

    if (!selectedLanguage || selectedLevel === null) {
      return students.map((student: IStudentBasicInfoDto) => ({
        ...student,
        disabled: false
      }));
    }

    return students
      .map((student: IStudentBasicInfoDto) => {
        const hasMatchingLanguage = student.studentTeachingLanguages!.some((lang: any) =>
          lang.teachingLanguageId === selectedLanguage.id && lang.languageLevel === selectedLevel.code
        );

        return {
          ...student,
          disabled: !hasMatchingLanguage,
          match: hasMatchingLanguage
        };
      })
      .sort((a: any, b: any) => {
        if (a.match && !b.match) return -1;
        if (!a.match && b.match) return 1;
        return 0;
      })
      .map((student: any) => {
        const {match, ...rest} = student;
        return rest;
      });
  });
  teachingLanguages$ = computed(() => {
    const teachingLanguages = this.dataStateService.teachingLanguages.state()?.data?.teachingLanguages || [];
    const students = this.dataStateService.parentStudents.state()?.data?.students || [];
  
    // Create a Set of teaching language IDs that have related students
    const studentsLanguageIds = new Set(students.flatMap((student: IStudentBasicInfoDto) => 
      student.studentTeachingLanguages!.map(lang => lang.teachingLanguageId)
    ));
  
    // Sort teaching languages based on whether they have related students
    return teachingLanguages.sort((a: ITeachingLanguageDto, b: ITeachingLanguageDto) => {
      const aHasStudents = studentsLanguageIds.has(a.id!); // Check if language a has students
      const bHasStudents = studentsLanguageIds.has(b.id!); // Check if language b has students
  
      // Sort such that languages with students come first
      return (aHasStudents === bHasStudents) ? 0 : (aHasStudents ? -1 : 1);
    });
  });

  // Other properties and methods
  canGoBack = signal(false);
  Severity = Severity;
  #userToSignal = this.authService.userDecodedJWTData$;
  selectedStudents = signal([] as any[]);
  selectedTeachingLanguage = {} as ITeachingLanguageDto;
  selectedTeachingLanguage$ = signal<ITeachingLanguageDto | null>(null);
  selectedLanguageLevel$ = signal<any | null>(null);
  resetSelectionSignal = signal(false);
  ruleMessageDisplay = signal([] as StoudentGroupRuleMessage[]);
  destroy: DestroyRef = inject(DestroyRef);
  subscriptions: Subscription[] = [];
  parameters: any;
  preselectedStudents = [] as IStudentBasicInfoDto[];
  filteredStudents = [] as any[];
  preselectedLevel = [];
  studentGroups = [] as IStudentGroupDto[];
  studentsToAdd: string[] = [];
  studentsToRemove: string[] = [];
  studentLevels$ = computed(() => {
    const levels = Object.keys(ILanguageLevelsEnum)
      .filter(key => isNaN(Number(key)))
      .map(key => ({name: key, code: ILanguageLevelsEnum[key as keyof typeof ILanguageLevelsEnum]}));

    if (!this.students$()) {
      return [];
    }
    return this.studentGroupService.sortLanguageLevels(levels, this.students$());
  });
  studentGroups$ = computed(() => this.dataStateService.parentStudentsGroups.state() as State<IStudentGroupDto[]>);
  createStudentGroup$ = computed(() => this.dataStateService.createStudentGroup.state() as State<ICreateStudentGroupResponse> || {} as State<ICreateStudentGroupResponse>);
  untilDestroyed = untilDestroyed();
  injector = inject(Injector);
  shouldDisableCreateButton = computed(() => {
    const selectedStudents = this.selectedStudents();
    const selectedLanguage = this.selectedTeachingLanguage$();

    return this.ruleMessageDisplay().length > 0 || selectedStudents.length === 0 || !selectedLanguage;
  });
  shouldDisableEditSaveChangesButton = computed(() => {
    const selectedStudents = this.selectedStudents();
    const selectedLanguage = this.selectedTeachingLanguage$();

    return this.ruleMessageDisplay().length > 0
      || (this.studentGroupService.studentsToAdd.length === 0 && this.studentGroupService.studentsToRemove.length === 0);
  });

  constructor(@Inject('dialogParameters') parameters: any) {
    this.parameters = (parameters);
  }

  ngOnInit(): void {
    this.initializeComponent();
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.generalService.setErrorDataSignal('');
  }

  getFilteredStudents(): IStudentBasicInfoDto[] {
    const students = this.dataStateService.parentStudents.state()?.data?.students || [];
    const selectedLanguage = this.selectedTeachingLanguage;
    const selectedLevel = this.selectedLanguageLevel$();

    if (!selectedLanguage || selectedLevel === null) {
      return students.map((student: any) => ({...student, disabled: false}));
    }

    return students
      .map(this.mapAndFilterStudent.bind(this))
      .sort(this.studentGroupService.sortStudents)
      .map(this.removeMatchProperty);
  }

  areAllStudentLevelsDisabled(): boolean {
    return this.studentLevels$().every(level => level.disabled === true);
  }

  onLanguageSelected(event: ITeachingLanguageDto): void {
    this.selectedTeachingLanguage = event;
    this.selectedTeachingLanguage$.set(event);
    this.resetSelectedStudents();
    this.filteredStudents = this.getFilteredStudents();
  }

  onLevelSelected(event: any): void {
    this.selectedLanguageLevel$.set(event);
    this.resetSelectedStudents();
    this.filteredStudents = this.getFilteredStudents();
  }

  /**
   * Handles student selection, updates the rule messages and validates the students
   * for any rules that may have been violated. If in edit mode, also updates the
   * students in the student group service.
   * @param event The selected student
   */
  onStudentSelected(event: IStudentBasicInfoDto & { selected: boolean } & IStudentBasicInfoDto): void {
    this.resetRuleMessages();
    console.log(this.selectedStudents());
    this.updateSelectedStudents(event as IStudentBasicInfoDto & { selected: boolean });
    this.generalService.setErrorDataSignal('');
    if (this.editMode()) {
      console.log(this.studentGroupItem());
      this.studentGroupService.onStudentSelected(event, this.studentGroupItem().studentsBasicInfo);
      this.validateStudents();
    }
    this.validateStudents();
    console.log(this.selectedStudents());

  }

  /**
   * Calls the API to create a student group.
   *
   * Emits: this.handleCreateGroupSuccess, this.handleCreateStudentGroupError
   */
  createNewGroup(): void {
    if (this.shouldDisableCreateButton()) return;

    this.studentGroupService.updateStudentGroupRequest({
      parentId: this.user()!.id,
      studentsToAdd: this.getStudentIds(),
      teachingLanguageId: this.selectedTeachingLanguage$()!.id,
    });

    this.onMembersStepSubmitted.emit();
    // const apiCall: Observable<any> = this.userService.createStudentGroup({
    //     parentId: this.user()!.id,
    //     studentsToAdd: this.getStudentIds(),
    //     teachingLanguageId: this.selectedTeachingLanguage$()!.id,
    //     studentToLanguageDto: {
    //         languageOfInterestId: this.selectedTeachingLanguage$()!.id,
    //         languageName: this.selectedTeachingLanguage$()!.name,
    //         languageLevel: this.selectedLanguageLevel$()!.code,
    //         studentLevel: 1,
    //         availability: 1,
    //         availabilitySupportedText: "test",
    //         moreDetails: "test"
    //     }
    // });

    // this.dataStateService.handleApiCall(apiCall, this.dataStateService.createStudentGroup.setState)
    //     .pipe(takeUntilDestroyed(this.destroy))
    //     .subscribe({
    //         next: this.handleCreateGroupSuccess,
    //         error: this.handleCreateStudentGroupError
    //     });
  }

  /**
   * Calls the API to edit a student group.
   *
   * Emits: this.handleEditGroupSuccess, this.handleCreateStudentGroupError
   */
  editGroupSave(): void {
    this.apiService.getApiData<IEditStudentGroupResponse>(
      {url: StudentGroupRoutes.patchEditStudentGroup, method: 'PATCH'},
      {
        parentId: this.user()?.id,
        groupId: this.studentGroupItem().id,
        studentLevel: IStudentLevelEnum.NoExperience,
        moreDetails: "",
        studentsToAdd: this.studentGroupService.studentsToAdd,
        studentsToRemove: this.studentGroupService.studentsToRemove,
        // studentToLanguageDto: {
        //   languageOfInterestId: this.selectedTeachingLanguage$()!.id,
        //   languageName: this.selectedTeachingLanguage$()!.name,
        //   languageLevel: this.selectedLanguageLevel$()!.code,
        //   studentLevel: 1,
        //   availability: 1,
        //   availabilitySupportedText: "test",
        //   moreDetails: "test"
        // }
      } as IEditStudentGroupRequest)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: this.handleEditGroupSuccess,
        error: this.handleCreateStudentGroupError
      });
  }

  /**
   * Initializes the component.
   *
   * Sets the student group item parameter, if in edit mode, initializes edit mode
   * and validates the students for any rules that may have been violated. Finally,
   * initializes the events.
   */
  private initializeComponent(): void {
    this.studentGroupItem.set(this.parameters.studentGroupItem);
    if (this.editMode()) {
      this.initializeEditMode();
      this.validateStudents();
    }

    if (this.studentGroupItem()) {
    }

    this.initEvents();
  }

  /**
   * Sets up subscriptions to the student groups and teaching languages observables.
   * When the student groups observable emits a new value, it validates the students
   * for any rules that may have been violated. When the teaching languages observable
   * emits a new value, it validates the students for any rules that may have been
   * violated and handles the languages in edit mode.
   */
  private setupSubscriptions(): void {
    this.subscribeToStudentGroups();
    this.subscribeToTeachingLanguages();
    this.subscribeToEditGroupState();
  }

  /**
   * Subscribes to the student groups observable and updates the student group item in edit mode and the student groups list.
   * It also subscribes to the selected students observable and logs the selected students to the console.
   */
  private subscribeToStudentGroups(): void {
    toObservable(this.studentGroups$, {injector: this.injector})
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (stateData: any) => {
          console.log(stateData);
          if (stateData.data?.studentGroups) {
            if (this.editMode()) {
              this.studentGroupItem.set(this.studentGroupService.findGroupById(stateData.data.studentGroups, this.studentGroupItem().id) as IStudentGroupDto);

            }
            this.studentGroups = stateData.data.studentGroups;
          }
        }
      });
  }

  private subscribeToEditGroupState(): void {
    toObservable(this.editGroupState, {injector: this.injector})
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (editGroupState: GroupDialogState) => {
          if (editGroupState === GroupDialogState.AfterEditSuccess) {
            this.groupItemChanged.emit(this.studentGroupItem());
          }
        }
      });
  }

  private subscribeToTeachingLanguages(): void {
    toObservable(this.teachingLanguages$, {injector: this.injector})
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (teachingLanguages: any) => {
          if (teachingLanguages.length > 0 && this.editMode()) {
            this.handleTeachingLanguagesInEditMode(teachingLanguages);
          }
        }
      });
  }

  private handleTeachingLanguagesInEditMode(teachingLanguages: any): void {

    const languageObject = this.studentGroupService.getTeachingLanguageObjectFromName(
      teachingLanguages,
      this.studentGroupItem().groupLanguageName
    );
    this.selectedTeachingLanguage = languageObject as ITeachingLanguageDto;
    this.filteredStudents = this.getFilteredStudents();
  }

  /**
   * Initializes the component in edit mode. Sets the step to 2 (Student Selection),
   * pre-selects the teaching language, level and students from the given StudentGroupItem,
   * and sets the initial filtered students list.
   */
  private initializeEditMode(): void {
    this.step.set(2);
    const studentGroupItem = this.parameters.studentGroupItem;
    const languageObject = this.studentGroupService.getTeachingLanguageObjectFromName(
      this.teachingLanguages$(),
      studentGroupItem.groupLanguageName
    );
    this.onLanguageSelected(languageObject as ITeachingLanguageDto);
    this.onLevelSelected({
      name: this.generalService.getILanguageLevelsEnumText(studentGroupItem.groupLevel),
      code: studentGroupItem.groupLevel,
      disabled: false
    });
    this.preselectedStudents = studentGroupItem.studentsBasicInfo;

    if (this.preselectedStudents) {
      this.selectedStudents.set(this.preselectedStudents
        .filter(Boolean)
        .map(this.studentGroupService.mapStudentToBasicInfo));

      console.log(this.selectedStudents());
    }
    this.filteredStudents = this.getFilteredStudents();
  }

  /**
   * Maps a student with packages to a simplified object with additional properties for selection and matching in the student selection list.
   * @param student The student to map.
   * @returns A simplified object with the student's id, first and last name, timezone IANA, timezone display name, disabled, selected, and match properties.
   * - disabled: Whether the student is disabled in the list based on whether they have a matching language and level.
   * - selected: Whether the student is selected in the list based on whether they are in the current group and have a matching language and level.
   * - match: Whether the student matches the current group's language and level.
   */
  private mapAndFilterStudent(student: IStudentBasicInfoDto): any {
    const hasMatchingLanguage = student.studentTeachingLanguages!.some(lang =>
      lang.teachingLanguageId === this.selectedTeachingLanguage.id &&
      lang.languageLevel === this.selectedLanguageLevel$().code
    );

    const groupStudents = this.studentGroupItem()?.studentsBasicInfo || [];
    const studentExists = this.studentGroupService.studentExistsInGroup(student.id, groupStudents);

    return {
      ...student,
      disabled: !hasMatchingLanguage,
      selected: this.editMode() && hasMatchingLanguage && studentExists,
      match: this.editMode() && hasMatchingLanguage && studentExists,
    };
  }

  private removeMatchProperty(student: any): any {
    const {match, ...rest} = student;
    return rest;
  }


  /**
   * Validates the selected students against all defined rules and sets the rule messages in the ruleMessageDisplay subject.
   * This is called when a student is selected or deselected, and in edit mode, when the language and level are changed.
   * @private
   */
  private validateStudents(): void {
    const selectedStudents = this.selectedStudents();
    const selectedStudentsBasicInfo = selectedStudents.map(student => student.studentBasicInfo);

    const newGroup = {
      groupLanguageName: this.selectedTeachingLanguage$()?.name,
      groupLevel: this.selectedLanguageLevel$()?.code,
      studentsBasicInfo: selectedStudents,
    };
    const ruleMessages = this.studentGroupService.validateSelectedStudents(this.studentGroups, newGroup, this.editMode());
    this.ruleMessageDisplay.set(ruleMessages);
  }

  private handleCreateGroupSuccess = (): void => {
    this.groupStateChanged.emit(this.EditGroupState.AfterCreateSuccess);
    this.toastService.show(ToastMessages.StudentsGroupAdd.success);
    this.eventBusService.emit(new EmitEvent(Events.StudentGroupAdded, {name: 'Group 1'}));

  }

  private handleEditGroupSuccess = (): void => {
    this.resetAfterEdit();
    this.eventBusService.emit(new EmitEvent(Events.StudentGroupEdited, undefined));
    this.toastService.show(ToastMessages.StudensGroupEdit.success);
    this.groupItemChanged.emit(this.studentGroupItem());
    this.groupStateChanged.emit(this.EditGroupState.AfterEditSuccess);
  }

  private handleCreateStudentGroupError = (error: IApiResponseBase): void => {
    console.error(error);
    if (error.statusCode === 400) {
      // Handle 400 error
    }
    this.generalService.handleRouteError(error, StudentGroupRoutes.postCreateStudentGroup);
  }

  private getStudentIds(): string[] {
    return this.selectedStudents().map(student => student.id);
  }

  /**
   * Updates the selected students list by either adding the given student or removing it if it's already present.
   * @param event The student to add or remove from the selected students list. Can be either a IStudentBasicInfoDto or an object with an id property.
   */
  private updateSelectedStudents(event: IStudentBasicInfoDto & { selected: boolean }): void {
    console.log(event);
    this.selectedStudents.update(students => {
      const studentId = event.id; // Get the student ID
      const studentIndex = students.findIndex(
        student => student.id === studentId // Compare with studentBasicInfo.id
      );

      // If the student is already in the list, remove it; otherwise, add it
      return studentIndex > -1
        ? students.filter((_, index) => index !== studentIndex) // Remove the student
        : [...students, event]; // Add the new student
    });
  }

  private resetSelectedStudents(): void {
    this.selectedStudents.set([]);
    this.preselectedStudents = [];
    this.resetSelectionSignal.set(!this.resetSelectionSignal());
  }

  private initEvents(): void {
    this.eventBusService.emit(new EmitEvent(Events.StateLoadTeachingLanguages, undefined));
    this.subscribeToEvents();
  }

  private resetRuleMessages(): void {
    this.ruleMessageDisplay.set([]);
  }

  private resetAfterEdit(): void {
    this.studentGroupService.studentsToAdd = [];
    this.studentGroupService.studentsToRemove = [];
    this.resetSelectedStudents();
  }

  private subscribeToEvents(): void {
    const subscriptions = [
      this.eventBusService.on(Events.StudentGroupAdded, this.handleStudentGroupAdded),
      this.eventBusService.on(Events.StudentGroupRemoved, this.handleStudentGroupRemoved),
      this.eventBusService.on(Events.StudentGroupNewMemberAdded, this.handleStudentGroupNewMemberAdded)
    ];
    this.subscriptions.push(...subscriptions);
  }

  private handleStudentGroupAdded = (payload: any): void => {
    console.log(`Customer Selected: ${payload.name}`);
  }

  private handleStudentGroupRemoved = (payload: any): void => {
    console.log(Events.StudentGroupRemoved, `: ${payload}`);
  }

  private handleStudentGroupNewMemberAdded = (payload: any): void => {
    console.log(Events.StudentGroupNewMemberAdded, `: ${payload}`);
  }

}
