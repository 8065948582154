import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  type OnInit,
  Output
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  AvailabilityTimePickerSelectorComponent
} from './availability-time-picker-selector/availability-time-picker-selector.component';
import {ButtonModule} from 'primeng/button';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {AvailabilityTimeSlotPickerService} from 'src/app/core/services/availability-time-slot-picker.service';
import moment from 'moment-timezone';
import {AuthService} from 'src/app/core/services/auth.service';
import { AvailabilityTimeConverter } from 'src/app/core/services/availability-helper';
import { LocalTimeService } from 'src/app/core/services/local-time.service';
import { Subscription } from 'rxjs';
import { IWeekDayTimeSlotDto } from '@GeneratedTsFiles/Availability/IWeekDayTimeSlotDto';

@Component({
    selector: 'app-availability-day-time-picker',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        MultiSelectModule,
        ToggleButtonModule,
        AvailabilityTimePickerSelectorComponent,
    ],
    templateUrl: './availability-day-time-picker.component.html',
    styleUrl: './availability-day-time-picker.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailabilityDayTimePickerComponent implements OnInit {
  authService = inject(AuthService);
  availabilityTimeSlotPicker = inject(AvailabilityTimeSlotPickerService);
  availabilityTimeConverter = inject(AvailabilityTimeConverter);
  localTimeService = inject(LocalTimeService);
  @Input() daysOfWeek = [
    {label: 'Monday', value: 'Monday'},
    {label: 'Tuesday', value: 'Tuesday'},
    {label: 'Wednesday', value: 'Wednesday'},
    {label: 'Thursday', value: 'Thursday'},
    {label: 'Friday', value: 'Friday'},
    {label: 'Saturday', value: 'Saturday'},
    {label: 'Sunday', value: 'Sunday'},
  ];
  @Input() availabilityData: unknown[] = [{
    "dayOfWeek": 0,
    "timeSlots": [
      {
        "startTime": "12:00:00",
        "endTime": "13:00:00"
      }
    ]
  },
  {
    "dayOfWeek": 1,
    "timeSlots": [
      {
        "startTime": "12:00:00",
        "endTime": "13:00:00"
      }
    ]
  }
]; // Input property for availability data

  @Output() timeSlotsChange = new EventEmitter<any>(); // Adjust the output type as needed
  timeSlotForm: FormGroup;
  selectedDays: { index: number; day: string }[] = []; // Array with index and day
  selectedDaysToggle: { [key: string]: boolean } = {};
  showCopyContentIndex: number = -1;
  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });
  localTime = this.localTimeService.localTime;
  private subscription: Subscription = new Subscription();


  constructor(private fb: FormBuilder) {
    this.timeSlotForm = this.fb.group({
      timeSlots: this.fb.array([], [Validators.required]) // Initialize the time slots array
    });

  }

  ngOnInit(): void {
    this.availabilityTimeSlotPicker.initializeTimeSlots(this.daysOfWeek, this.timeSlotForm.get('timeSlots') as FormArray);
    this.subscribeToFormChanges();
    this.initializeFormFromData(this.availabilityData as IWeekDayTimeSlotDto[]);

  }

  toggleDay(day: string, index: number): void {
    this.selectedDaysToggle[day] = this.selectedDaysToggle[day];
    console.log(this.selectedDaysToggle[day]);
    if (this.selectedDaysToggle[day]) {
      this.toggleCopyIndex(-1);
    } else {
      this.removeAllTimeSlotsForDay(index);
    }
  }

  toggleCopyIndex(index: number): void {
    if (this.showCopyContentIndex === index) {
      this.showCopyContentIndex = -1; // Collapse the currently shown index
    } else {
      this.showCopyContentIndex = index; // Show the new index
    }
  }

  removeAllTimeSlotsForDay(dayIndex: number): void {
    // Get the time slots array for the specific day
    const timeSlotsArray = this.timeSlotForm.get('timeSlots') as FormArray;

    // Check if the day index is valid
    if (dayIndex >= 0 && dayIndex < timeSlotsArray.length) {
      const dayTimeSlots = timeSlotsArray.at(dayIndex).get('timeSlots') as FormArray;

      // Clear all time slots for the specified day
      dayTimeSlots.clear();
    } else {
      console.error("Invalid day index:", dayIndex);
    }
  }

  getSelectedDays(): string[] {
    return Object.keys(this.selectedDaysToggle).filter(day => this.selectedDaysToggle[day]);
  }

  getSelectedDaysLength(): number {
    return this.selectedDays.length;
  }

  filterAvailableDays(index: number): any[] {
    // Filter out the current day from the daysOfWeek array
    return this.daysOfWeek.filter((day, i) =>
      i !== index
    );
  }

  // Add a time slot for a specific day
  addTimeSlot(dayIndex: number): void {
    const timeSlotsArray = this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, dayIndex);
    this.availabilityTimeSlotPicker.addTimeSlot(timeSlotsArray);
  }

  // Helper function to get time slots as a FormArray
  getTimeSlotsArray(): FormArray {
    return this.timeSlotForm.get('timeSlots') as FormArray;
  }

  removeTimeSlot(dayIndex: number, timeSlotIndex: number): void {
    // Get the time slots array for the specific day
    const timeSlotsArray = (this.timeSlotForm.get('timeSlots') as FormArray).at(dayIndex).get('timeSlots') as FormArray;

    // Remove the time slot at the given index
    timeSlotsArray.removeAt(timeSlotIndex);

    // Recheck the validity of all time slots for the specific day
    this.checkAllTimeSlotsValidity(timeSlotsArray);
  }

  checkAllTimeSlotsValidity(dayTimeSlots: FormArray): void {
    for (let i = 0; i < dayTimeSlots.length; i++) {
      const timeSlot = dayTimeSlots.at(i) as FormGroup;
      const startTime = moment(timeSlot.get('startTime')?.value);
      const endTime = moment(timeSlot.get('endTime')?.value);

      let conflictingSlots: { start: Date; end: Date }[] = [];

      for (let j = 0; j < dayTimeSlots.length; j++) {
        if (i === j) continue; // Skip the current slot

        const existingSlot = dayTimeSlots.at(j) as FormGroup;
        const existingStartTime = moment(existingSlot.get('startTime')?.value);
        const existingEndTime = moment(existingSlot.get('endTime')?.value);

        // Check for overlap
        if (startTime < existingEndTime && endTime > existingStartTime) {
          conflictingSlots.push({
            start: existingStartTime.toDate(),
            end: existingEndTime.toDate()
          });
        }
      }

      // Update validity based on conflicts found
      if (conflictingSlots.length > 0) {
        timeSlot.setErrors({overlap: true, conflictingSlots});
      } else {
        timeSlot.setErrors(null); // Clear any previous errors
      }
    }
  }

  // Helper function to compare only time
  getDayTimeSlots(dayIndex: number): FormArray {
    return this.timeSlotForm.get('timeSlots')!.get(dayIndex.toString())?.get('timeSlots') as FormArray;
  }

  // Returns the maximum end time for the startTime picker to avoid overlapping

  copyTimeSlotsToSelectedDays(currentDayIndex: number): void {
    const timeSlotsArray = this.timeSlotForm.get('timeSlots') as FormArray;
    const currentDayGroup = timeSlotsArray.at(currentDayIndex) as FormGroup;
    const currentTimeSlots = currentDayGroup.get('timeSlots') as FormArray;

    // Get the selected days excluding the current day
    const selectedDays = this.selectedDays.filter((_, index) => index !== currentDayIndex - 1);

    // Copy time slots to each selected day

    // Iterate over selected days to copy time slots
    this.selectedDays.forEach(selectedDay => {
      if (selectedDay.index !== currentDayIndex) { // Avoid copying to itself
        const targetDayGroup = timeSlotsArray.at(selectedDay.index) as FormGroup;
        const targetTimeSlots = targetDayGroup.get('timeSlots') as FormArray;

        // Clear existing time slots if needed
        targetTimeSlots.clear();

        // Copy current time slots
        currentTimeSlots.controls.forEach(slot => {
          targetTimeSlots.push(this.fb.group({
            startTime: [slot.get('startTime')?.value],
            endTime: [slot.get('endTime')?.value]
          }));
        });

        // Toggle the day in selectedDaysToggle
        this.selectedDaysToggle[selectedDay.day] = true; // Enable the day
      }
    });
  }

  checkEndTimeTimeslotOfDayIsMidnight(dayIndex: number, timeSlotIndex: number): boolean {
    const dayTimeSlots = this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, dayIndex);
    const currentTimeSlot = dayTimeSlots.at(timeSlotIndex) as FormGroup;
    const currentEndTimeValue = currentTimeSlot.get('endTime')?.value;
    const currentEndTime = (moment(currentEndTimeValue).format('HH:mm'));
    return currentEndTime === '00:00';
  }

  // Method to handle selection of days
  onDaySelect(selectedValues: any[]): void {
    this.selectedDays = selectedValues.map(day => {
      const index = this.daysOfWeek.findIndex(d => d.value === day.value);
      return {index, day: day.value}; // Create object with index and day value
    });
  }

  // Validate time slots and emit changes
  onTimeSlotsChange(selectedTimeData: { time: Date, isStartTime: boolean }, dayIndex: number, timeSlotIndex: number) {
    const timeSlot = this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, dayIndex).at(timeSlotIndex) as FormGroup;
    const selectedDateTime = moment(selectedTimeData.time);
    const selectedHour = selectedDateTime.hours();
    const selectedMinute = selectedDateTime.minutes();

    const updateSlotTime = (key: 'startTime' | 'endTime', newTime: Date) => {
      timeSlot.get(key)?.setValue(newTime);
    };

    const getAdjustedTime = (baseTime: Date, hourOffset: number) => {
      return moment(baseTime).add(hourOffset, 'hour').toDate();
    };

    const checkConflicts = (startTime: Date, endTime: Date): { start: Date, end: Date }[] => {
      const conflicts: { start: Date, end: Date }[] = [];
      const daySlots = this.availabilityTimeSlotPicker.getDayTimeSlots(this.timeSlotForm, dayIndex);

      for (let i = 0; i < daySlots.length; i++) {
        if (i === timeSlotIndex) continue;

        const existingSlot = daySlots.at(i) as FormGroup;
        const existingStartTime = moment(existingSlot.get('startTime')?.value);
        const existingEndTime = moment(existingSlot.get('endTime')?.value);

        if (startTime < existingEndTime.toDate() && endTime > existingStartTime.toDate()) {
          conflicts.push({start: existingStartTime.toDate(), end: existingEndTime.toDate()});
        }
      }
      return conflicts;
    };

    const selectedTimeKey = selectedTimeData.isStartTime ? 'startTime' : 'endTime';
    const startOrEndTime = moment(timeSlot.get(selectedTimeKey)?.value)
      .set({hour: selectedHour, minute: selectedMinute}).toDate();
    updateSlotTime(selectedTimeKey, startOrEndTime);

    if (selectedTimeData.isStartTime) {
      const endTime = getAdjustedTime(startOrEndTime, 1);
      const conflicts = checkConflicts(startOrEndTime, endTime);

      if (conflicts.length > 0) {
        // timeSlot.setErrors({overlap: true, conflictingSlots: conflicts});
        this.timeSlotForm.updateValueAndValidity();
        return;
      }

      timeSlot.setErrors(null);
      updateSlotTime('endTime', endTime);
    } else {
      const startTime = moment(timeSlot.get('startTime')?.value);
      if (startTime.isSameOrAfter(startOrEndTime)) {
        const newStartTime = getAdjustedTime(startOrEndTime, -1);
        updateSlotTime('startTime', newStartTime);
      }

      const conflicts = checkConflicts(timeSlot.get('startTime')?.value, startOrEndTime);
      if (conflicts.length > 0) {
        // timeSlot.setErrors({overlap: true, conflictingSlots: conflicts});
        this.timeSlotForm.updateValueAndValidity();
        return;
      }

      timeSlot.setErrors(null);
      this.timeSlotForm.updateValueAndValidity();
    }

    console.log(selectedTimeData.time, dayIndex, timeSlotIndex, selectedTimeData.isStartTime);
  }

  calculateTotalAvailability(): number {
    const timeSlotsArray = this.timeSlotForm.get('timeSlots') as FormArray;
    let totalHours = 0;

    // Iterate through each day (FormGroup) in the timeSlots FormArray
    timeSlotsArray.controls.forEach((dayGroup: any) => {
      const dailyTimeSlotsArray = dayGroup.get('timeSlots') as FormArray; // Access the nested timeSlots array

      // Iterate through each time slot (FormGroup) in the daily timeSlots FormArray
      dailyTimeSlotsArray.controls.forEach((timeSlot: any) => {
        const startTimeValue = timeSlot.get('startTime')?.value;
        const endTimeValue = timeSlot.get('endTime')?.value;

        // Log the values for debugging
        console.log(`Start Time: ${startTimeValue}, End Time: ${endTimeValue}`);

        if (startTimeValue && endTimeValue) {
          // Using moment to parse the time component (HH:mm)
          const startTime = moment(startTimeValue, 'HH:mm');
          let endTime = moment(endTimeValue, 'HH:mm');

          // Handle the case where the end time is at midnight (00:00)
          if (endTimeValue === '00:00') {
            // Treat 00:00 as the end of the previous day
            endTime = endTime.add(1, 'days').startOf('day').subtract(1, 'seconds'); // Move to the next day at midnight
          }

          // Calculate the duration in hours between start and end times
          const duration = moment.duration(endTime.diff(startTime));

          // Check for negative duration (e.g., if start is after end)
          if (duration.asHours() >= 0) {
            totalHours += duration.asHours();
          } else {
            console.warn(`Negative duration detected for time slot: ${startTimeValue} to ${endTimeValue}`);
          }
        }
      });
    });

    console.log(`Total Available Hours: ${totalHours}`); // Log the total for debugging
    return totalHours; // Return the total sum of hours
  }

  submit(): void {
    if (this.timeSlotForm.valid) {
      // Convert the FormArray data to IWeekDayTimeSlotDto[]
      const daysOfAvailability: IWeekDayTimeSlotDto[] = this.getDaysAvailability();
  
      // Convert time slots to UTC
      // TODO: get correct timezone 
      const timeZoneId = this.user()?.timeZoneIana!;
      const daysInUtc = this.availabilityTimeConverter.convertDaysToUtc(daysOfAvailability, timeZoneId);
      const daysInLocal = this.availabilityTimeConverter.convertDaysToLocal(daysOfAvailability, timeZoneId);
  
      this.timeSlotsChange.emit(daysInUtc); // Emit the transformed data
      console.log('Form submitted successfully with data daysInUtc:', daysOfAvailability);
      console.log('Form submitted successfully with data daysInLocal:', daysInLocal);
    } else {
      console.log('Form is invalid. Please check the entries.');
    }
  }
  
  // Helper method to transform form data into IWeekDayTimeSlotDto[]
  private getDaysAvailability(): IWeekDayTimeSlotDto[] {
    return this.timeSlotForm.get('timeSlots')!.value.map((daySlots: any, index: number) => {
      const adjustedIndex = (index + 1) % 7; // Adjust index to start from Monday

      const timeSlots: any[] = daySlots.timeSlots.map((slot: any) => ({
        startTime: this.availabilityTimeConverter.convertTimeToLocal(slot.startTime, this.user()?.timeZoneIana!),
        endTime: this.availabilityTimeConverter.convertTimeToLocal(slot.startTime, this.user()?.timeZoneIana!),
        // Include any other properties from the slot as needed
      }));
  
      return {
        dayOfWeek: adjustedIndex, // Using the index to represent the day of the week
        timeSlots,
      };
    });
  }

  private subscribeToFormChanges(): void {
    // Subscribe to the valueChanges observable of the entire form
    this.timeSlotForm.valueChanges.subscribe((formData) => {
      // Handle form changes here
      console.log('Form values changed:', formData);
      console.log('Form is ', this.timeSlotForm.valid ? 'valid' : 'invalid');
      if (this.timeSlotForm.valid) {
        // console.log('Form is valid');
      const daysOfAvailability: IWeekDayTimeSlotDto[] = this.getDaysAvailability();
      const timeZoneId = 'Australia/Perth';
      const daysInUtc = this.availabilityTimeConverter.convertDaysToUtc(daysOfAvailability, timeZoneId);
        this.timeSlotsChange.emit(daysInUtc);
      } else {
        console.log('Form is invalid');
      }
    });

    // Optionally, subscribe to individual controls or form arrays
    const timeSlotsArray = this.timeSlotForm.get('timeSlots') as FormArray;
    timeSlotsArray.controls.forEach((dayFormGroup, dayIndex) => {
      (dayFormGroup.get('timeSlots') as FormArray).controls.forEach((timeSlotFormGroup, timeSlotIndex) => {
        timeSlotFormGroup.valueChanges.subscribe((timeSlotData) => {
          console.log(`Day ${dayIndex + 1}, Slot ${timeSlotIndex + 1} changed:`, timeSlotData);
        });
      });
    });
  }

  private initializeFormFromData(data: IWeekDayTimeSlotDto[]): void {
    const timeSlotsArray = this.timeSlotForm.get('timeSlots') as FormArray;

    data.forEach((availability) => {
      const dayGroup = this.fb.group({
        dayOfWeek: [availability.dayOfWeek, Validators.required],
        timeSlots: this.fb.array(
          availability.timeSlots.map(slot => this.createTimeSlotFormGroup(slot))
        )
      });
      timeSlotsArray.push(dayGroup);
    });
  }

  private createTimeSlotFormGroup(slot: any): FormGroup {
    return this.fb.group({
      startTime: [slot.startTime, Validators.required],
      endTime: [slot.endTime, Validators.required],
    });
  }

}
