import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, NgZone, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ViewChild, ContentChild, ContentChildren, NgModule } from '@angular/core';
import { uuid, getHeight, addClass, removeClass } from '@primeuix/utils';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-scrollpanel-content-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
}

.p-scrollpanel-content {
    height: calc(100% + calc(2 * ${dt('scrollpanel.bar.size')}));
    width: calc(100% + calc(2 * ${dt('scrollpanel.bar.size')}));
    padding-inline: 0 calc(2 * ${dt('scrollpanel.bar.size')});
    padding-block: 0 calc(2 * ${dt('scrollpanel.bar.size')});
    position: relative;
    overflow: auto;
    box-sizing: border-box;
    scrollbar-width: none;
}

.p-scrollpanel-content::-webkit-scrollbar {
    display: none;
}

.p-scrollpanel-bar {
    position: relative;
    border-radius: ${dt('scrollpanel.bar.border.radius')};
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    outline-color: transparent;
    transition: outline-color ${dt('scrollpanel.transition.duration')};
    background: ${dt('scrollpanel.bar.background')};
    border: 0 none;
    transition: outline-color ${dt('scrollpanel.transition.duration')}, opacity ${dt('scrollpanel.transition.duration')};
}

.p-scrollpanel-bar:focus-visible {
    box-shadow: ${dt('scrollpanel.bar.focus.ring.shadow')};
    outline: ${dt('scrollpanel.barfocus.ring.width')} ${dt('scrollpanel.bar.focus.ring.style')} ${dt('scrollpanel.bar.focus.ring.color')};
    outline-offset: ${dt('scrollpanel.barfocus.ring.offset')};
}

.p-scrollpanel-bar-y {
    width: ${dt('scrollpanel.bar.size')};
    top: 0;
}

.p-scrollpanel-bar-x {
    height: ${dt('scrollpanel.bar.size')};
    bottom: 0;
}

.p-scrollpanel-hidden {
    visibility: hidden;
}

.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
    opacity: 1;
}

.p-scrollpanel-grabbed {
    user-select: none;
}
`;
const classes = {
  root: 'p-scrollpanel p-component',
  contentContainer: 'p-scrollpanel-content-container',
  content: 'p-scrollpanel-content',
  barX: 'p-scrollpanel-bar p-scrollpanel-bar-x',
  barY: 'p-scrollpanel-bar p-scrollpanel-bar-y'
};
class ScrollPanelStyle extends BaseStyle {
  name = 'scrollpanel';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵScrollPanelStyle_BaseFactory;
    return function ScrollPanelStyle_Factory(__ngFactoryType__) {
      return (ɵScrollPanelStyle_BaseFactory || (ɵScrollPanelStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ScrollPanelStyle)))(__ngFactoryType__ || ScrollPanelStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: ScrollPanelStyle,
    factory: ScrollPanelStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollPanelStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * ScrollPanel is a cross browser, lightweight and themable alternative to native browser scrollbar.
 *
 * [Live Demo](https://www.primeng.org/scrollpanel/)
 *
 * @module scrollpanelstyle
 *
 */
var ScrollPanelClasses;
(function (ScrollPanelClasses) {
  /**
   * Class name of the root element
   */
  ScrollPanelClasses["root"] = "p-scrollpanel";
  /**
   * Class name of the content container element
   */
  ScrollPanelClasses["contentContainer"] = "p-scrollpanel-content-container";
  /**
   * Class name of the content element
   */
  ScrollPanelClasses["content"] = "p-scrollpanel-content";
  /**
   * Class name of the bar x element
   */
  ScrollPanelClasses["barX"] = "p-scrollpanel-bar-x";
  /**
   * Class name of the bar y element
   */
  ScrollPanelClasses["barY"] = "p-scrollpanel-bar-y";
})(ScrollPanelClasses || (ScrollPanelClasses = {}));
const _c0 = ["content"];
const _c1 = ["container"];
const _c2 = ["xBar"];
const _c3 = ["yBar"];
const _c4 = ["*"];
function ScrollPanel_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function ScrollPanel_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
/**
 * ScrollPanel is a cross browser, lightweight and themable alternative to native browser scrollbar.
 * @group Components
 */
class ScrollPanel extends BaseComponent {
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Step factor to scroll the content while pressing the arrow keys.
   * @group Props
   */
  step = 5;
  containerViewChild;
  contentViewChild;
  xBarViewChild;
  yBarViewChild;
  /**
   * Defines template option for content.
   * @group Templates
   */
  contentTemplate;
  templates;
  _contentTemplate;
  scrollYRatio;
  scrollXRatio;
  timeoutFrame = fn => setTimeout(fn, 0);
  initialized = false;
  lastPageY;
  lastPageX;
  isXBarClicked = false;
  isYBarClicked = false;
  lastScrollLeft = 0;
  lastScrollTop = 0;
  orientation = 'vertical';
  timer;
  contentId;
  windowResizeListener;
  contentScrollListener;
  mouseEnterListener;
  xBarMouseDownListener;
  yBarMouseDownListener;
  documentMouseMoveListener;
  documentMouseUpListener;
  _componentStyle = inject(ScrollPanelStyle);
  zone = inject(NgZone);
  ngOnInit() {
    super.ngOnInit();
    this.contentId = uuid('pn_id_') + '_content';
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.moveBar();
        this.moveBar = this.moveBar.bind(this);
        this.onXBarMouseDown = this.onXBarMouseDown.bind(this);
        this.onYBarMouseDown = this.onYBarMouseDown.bind(this);
        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.onDocumentMouseUp = this.onDocumentMouseUp.bind(this);
        this.windowResizeListener = this.renderer.listen(window, 'resize', this.moveBar);
        this.contentScrollListener = this.renderer.listen(this.contentViewChild.nativeElement, 'scroll', this.moveBar);
        this.mouseEnterListener = this.renderer.listen(this.contentViewChild.nativeElement, 'mouseenter', this.moveBar);
        this.xBarMouseDownListener = this.renderer.listen(this.xBarViewChild.nativeElement, 'mousedown', this.onXBarMouseDown);
        this.yBarMouseDownListener = this.renderer.listen(this.yBarViewChild.nativeElement, 'mousedown', this.onYBarMouseDown);
        this.calculateContainerHeight();
        this.initialized = true;
      });
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this._contentTemplate = item.template;
          break;
        default:
          this._contentTemplate = item.template;
          break;
      }
    });
  }
  calculateContainerHeight() {
    let container = this.containerViewChild.nativeElement;
    let content = this.contentViewChild.nativeElement;
    let xBar = this.xBarViewChild.nativeElement;
    const window = this.document.defaultView;
    let containerStyles = window.getComputedStyle(container),
      xBarStyles = window.getComputedStyle(xBar),
      pureContainerHeight = getHeight(container) - parseInt(xBarStyles['height'], 10);
    if (containerStyles['max-height'] != 'none' && pureContainerHeight == 0) {
      if (content.offsetHeight + parseInt(xBarStyles['height'], 10) > parseInt(containerStyles['max-height'], 10)) {
        container.style.height = containerStyles['max-height'];
      } else {
        container.style.height = content.offsetHeight + parseFloat(containerStyles.paddingTop) + parseFloat(containerStyles.paddingBottom) + parseFloat(containerStyles.borderTopWidth) + parseFloat(containerStyles.borderBottomWidth) + 'px';
      }
    }
  }
  moveBar() {
    let container = this.containerViewChild.nativeElement;
    let content = this.contentViewChild.nativeElement;
    /* horizontal scroll */
    let xBar = this.xBarViewChild.nativeElement;
    let totalWidth = content.scrollWidth;
    let ownWidth = content.clientWidth;
    let bottom = (container.clientHeight - xBar.clientHeight) * -1;
    this.scrollXRatio = ownWidth / totalWidth;
    /* vertical scroll */
    let yBar = this.yBarViewChild.nativeElement;
    let totalHeight = content.scrollHeight;
    let ownHeight = content.clientHeight;
    let right = (container.clientWidth - yBar.clientWidth) * -1;
    this.scrollYRatio = ownHeight / totalHeight;
    this.requestAnimationFrame(() => {
      if (this.scrollXRatio >= 1) {
        xBar.setAttribute('data-p-scrollpanel-hidden', 'true');
        addClass(xBar, 'p-scrollpanel-hidden');
      } else {
        xBar.setAttribute('data-p-scrollpanel-hidden', 'false');
        removeClass(xBar, 'p-scrollpanel-hidden');
        const xBarWidth = Math.max(this.scrollXRatio * 100, 10);
        const xBarLeft = Math.abs(content.scrollLeft * (100 - xBarWidth) / (totalWidth - ownWidth));
        xBar.style.cssText = 'width:' + xBarWidth + '%; inset-inline-start:' + xBarLeft + '%;bottom:' + bottom + 'px;';
      }
      if (this.scrollYRatio >= 1) {
        yBar.setAttribute('data-p-scrollpanel-hidden', 'true');
        addClass(yBar, 'p-scrollpanel-hidden');
      } else {
        yBar.setAttribute('data-p-scrollpanel-hidden', 'false');
        removeClass(yBar, 'p-scrollpanel-hidden');
        const yBarHeight = Math.max(this.scrollYRatio * 100, 10);
        const yBarTop = content.scrollTop * (100 - yBarHeight) / (totalHeight - ownHeight);
        yBar.style.cssText = 'height:' + yBarHeight + '%; top: calc(' + yBarTop + '% - ' + xBar.clientHeight + 'px); inset-inline-end:' + right + 'px;';
      }
    });
    this.cd.markForCheck();
  }
  onScroll(event) {
    if (this.lastScrollLeft !== event.target.scrollLeft) {
      this.lastScrollLeft = event.target.scrollLeft;
      this.orientation = 'horizontal';
    } else if (this.lastScrollTop !== event.target.scrollTop) {
      this.lastScrollTop = event.target.scrollTop;
      this.orientation = 'vertical';
    }
    this.moveBar();
  }
  onKeyDown(event) {
    if (this.orientation === 'vertical') {
      switch (event.code) {
        case 'ArrowDown':
          {
            this.setTimer('scrollTop', this.step);
            event.preventDefault();
            break;
          }
        case 'ArrowUp':
          {
            this.setTimer('scrollTop', this.step * -1);
            event.preventDefault();
            break;
          }
        case 'ArrowLeft':
        case 'ArrowRight':
          {
            event.preventDefault();
            break;
          }
        default:
          //no op
          break;
      }
    } else if (this.orientation === 'horizontal') {
      switch (event.code) {
        case 'ArrowRight':
          {
            this.setTimer('scrollLeft', this.step);
            event.preventDefault();
            break;
          }
        case 'ArrowLeft':
          {
            this.setTimer('scrollLeft', this.step * -1);
            event.preventDefault();
            break;
          }
        case 'ArrowDown':
        case 'ArrowUp':
          {
            event.preventDefault();
            break;
          }
        default:
          //no op
          break;
      }
    }
  }
  onKeyUp() {
    this.clearTimer();
  }
  repeat(bar, step) {
    this.contentViewChild.nativeElement[bar] += step;
    this.moveBar();
  }
  setTimer(bar, step) {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.repeat(bar, step);
    }, 40);
  }
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  bindDocumentMouseListeners() {
    if (!this.documentMouseMoveListener) {
      this.documentMouseMoveListener = e => {
        this.onDocumentMouseMove(e);
      };
      this.document.addEventListener('mousemove', this.documentMouseMoveListener);
    }
    if (!this.documentMouseUpListener) {
      this.documentMouseUpListener = e => {
        this.onDocumentMouseUp(e);
      };
      this.document.addEventListener('mouseup', this.documentMouseUpListener);
    }
  }
  unbindDocumentMouseListeners() {
    if (this.documentMouseMoveListener) {
      this.document.removeEventListener('mousemove', this.documentMouseMoveListener);
      this.documentMouseMoveListener = null;
    }
    if (this.documentMouseUpListener) {
      document.removeEventListener('mouseup', this.documentMouseUpListener);
      this.documentMouseUpListener = null;
    }
  }
  onYBarMouseDown(e) {
    this.isYBarClicked = true;
    this.yBarViewChild.nativeElement.focus();
    this.lastPageY = e.pageY;
    this.yBarViewChild.nativeElement.setAttribute('data-p-scrollpanel-grabbed', 'true');
    addClass(this.yBarViewChild.nativeElement, 'p-scrollpanel-grabbed');
    this.document.body.setAttribute('data-p-scrollpanel-grabbed', 'true');
    addClass(this.document.body, 'p-scrollpanel-grabbed');
    this.bindDocumentMouseListeners();
    e.preventDefault();
  }
  onXBarMouseDown(e) {
    this.isXBarClicked = true;
    this.xBarViewChild.nativeElement.focus();
    this.lastPageX = e.pageX;
    this.xBarViewChild.nativeElement.setAttribute('data-p-scrollpanel-grabbed', 'false');
    addClass(this.xBarViewChild.nativeElement, 'p-scrollpanel-grabbed');
    this.document.body.setAttribute('data-p-scrollpanel-grabbed', 'false');
    addClass(this.document.body, 'p-scrollpanel-grabbed');
    this.bindDocumentMouseListeners();
    e.preventDefault();
  }
  onDocumentMouseMove(e) {
    if (this.isXBarClicked) {
      this.onMouseMoveForXBar(e);
    } else if (this.isYBarClicked) {
      this.onMouseMoveForYBar(e);
    } else {
      this.onMouseMoveForXBar(e);
      this.onMouseMoveForYBar(e);
    }
  }
  onMouseMoveForXBar(e) {
    let deltaX = e.pageX - this.lastPageX;
    this.lastPageX = e.pageX;
    this.requestAnimationFrame(() => {
      this.contentViewChild.nativeElement.scrollLeft += deltaX / this.scrollXRatio;
    });
  }
  onMouseMoveForYBar(e) {
    let deltaY = e.pageY - this.lastPageY;
    this.lastPageY = e.pageY;
    this.requestAnimationFrame(() => {
      this.contentViewChild.nativeElement.scrollTop += deltaY / this.scrollYRatio;
    });
  }
  /**
   * Scrolls the top location to the given value.
   * @param scrollTop
   * @group Method
   */
  scrollTop(scrollTop) {
    let scrollableHeight = this.contentViewChild.nativeElement.scrollHeight - this.contentViewChild.nativeElement.clientHeight;
    scrollTop = scrollTop > scrollableHeight ? scrollableHeight : scrollTop > 0 ? scrollTop : 0;
    this.contentViewChild.nativeElement.scrollTop = scrollTop;
  }
  onFocus(event) {
    if (this.xBarViewChild.nativeElement.isSameNode(event.target)) {
      this.orientation = 'horizontal';
    } else if (this.yBarViewChild.nativeElement.isSameNode(event.target)) {
      this.orientation = 'vertical';
    }
  }
  onBlur() {
    if (this.orientation === 'horizontal') {
      this.orientation = 'vertical';
    }
  }
  onDocumentMouseUp(e) {
    this.yBarViewChild.nativeElement.setAttribute('data-p-scrollpanel-grabbed', 'false');
    removeClass(this.yBarViewChild.nativeElement, 'p-scrollpanel-grabbed');
    this.xBarViewChild.nativeElement.setAttribute('data-p-scrollpanel-grabbed', 'false');
    removeClass(this.xBarViewChild.nativeElement, 'p-scrollpanel-grabbed');
    this.document.body.setAttribute('data-p-scrollpanel-grabbed', 'false');
    removeClass(this.document.body, 'p-scrollpanel-grabbed');
    this.unbindDocumentMouseListeners();
    this.isXBarClicked = false;
    this.isYBarClicked = false;
  }
  requestAnimationFrame(f) {
    let frame = window.requestAnimationFrame || this.timeoutFrame;
    frame(f);
  }
  unbindListeners() {
    if (this.windowResizeListener) {
      this.windowResizeListener();
      this.windowResizeListener = null;
    }
    if (this.contentScrollListener) {
      this.contentScrollListener();
      this.contentScrollListener = null;
    }
    if (this.mouseEnterListener) {
      this.mouseEnterListener();
      this.mouseEnterListener = null;
    }
    if (this.xBarMouseDownListener) {
      this.xBarMouseDownListener();
      this.xBarMouseDownListener = null;
    }
    if (this.yBarMouseDownListener) {
      this.yBarMouseDownListener();
      this.yBarMouseDownListener = null;
    }
  }
  ngOnDestroy() {
    if (this.initialized) {
      this.unbindListeners();
    }
  }
  /**
   * Refreshes the position and size of the scrollbar.
   * @group Method
   */
  refresh() {
    this.moveBar();
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵScrollPanel_BaseFactory;
    return function ScrollPanel_Factory(__ngFactoryType__) {
      return (ɵScrollPanel_BaseFactory || (ɵScrollPanel_BaseFactory = i0.ɵɵgetInheritedFactory(ScrollPanel)))(__ngFactoryType__ || ScrollPanel);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: ScrollPanel,
    selectors: [["p-scroll-panel"], ["p-scrollPanel"], ["p-scrollpanel"]],
    contentQueries: function ScrollPanel_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c0, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function ScrollPanel_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c2, 5);
        i0.ɵɵviewQuery(_c3, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.xBarViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.yBarViewChild = _t.first);
      }
    },
    inputs: {
      style: "style",
      styleClass: "styleClass",
      step: [2, "step", "step", numberAttribute]
    },
    features: [i0.ɵɵProvidersFeature([ScrollPanelStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c4,
    decls: 11,
    vars: 17,
    consts: [["container", ""], ["content", ""], ["xBar", ""], ["yBar", ""], [3, "ngClass", "ngStyle"], [1, "p-scrollpanel-content-container"], [1, "p-scrollpanel-content", 3, "mouseenter", "scroll"], [4, "ngTemplateOutlet"], ["tabindex", "0", "role", "scrollbar", 1, "p-scrollpanel-bar", "p-scrollpanel-bar-x", 3, "mousedown", "keydown", "keyup", "focus", "blur"], ["tabindex", "0", "role", "scrollbar", 1, "p-scrollpanel-bar", "p-scrollpanel-bar-y", 3, "mousedown", "keydown", "keyup", "focus"]],
    template: function ScrollPanel_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 4, 0)(2, "div", 5)(3, "div", 6, 1);
        i0.ɵɵlistener("mouseenter", function ScrollPanel_Template_div_mouseenter_3_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.moveBar());
        })("scroll", function ScrollPanel_Template_div_scroll_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onScroll($event));
        });
        i0.ɵɵtemplate(5, ScrollPanel_Conditional_5_Template, 1, 0)(6, ScrollPanel_ng_container_6_Template, 1, 0, "ng-container", 7);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(7, "div", 8, 2);
        i0.ɵɵlistener("mousedown", function ScrollPanel_Template_div_mousedown_7_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onXBarMouseDown($event));
        })("keydown", function ScrollPanel_Template_div_keydown_7_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onKeyDown($event));
        })("keyup", function ScrollPanel_Template_div_keyup_7_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onKeyUp());
        })("focus", function ScrollPanel_Template_div_focus_7_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onFocus($event));
        })("blur", function ScrollPanel_Template_div_blur_7_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onBlur());
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 9, 3);
        i0.ɵɵlistener("mousedown", function ScrollPanel_Template_div_mousedown_9_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onYBarMouseDown($event));
        })("keydown", function ScrollPanel_Template_div_keydown_9_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onKeyDown($event));
        })("keyup", function ScrollPanel_Template_div_keyup_9_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onKeyUp());
        })("focus", function ScrollPanel_Template_div_focus_9_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onFocus($event));
        });
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", "p-scrollpanel p-component")("ngStyle", ctx.style);
        i0.ɵɵattribute("data-pc-name", "scrollpanel");
        i0.ɵɵadvance(2);
        i0.ɵɵattribute("data-pc-section", "wrapper");
        i0.ɵɵadvance();
        i0.ɵɵattribute("data-pc-section", "content");
        i0.ɵɵadvance(2);
        i0.ɵɵconditional(!ctx.contentTemplate && !ctx._contentTemplate ? 5 : -1);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate || ctx._contentTemplate);
        i0.ɵɵadvance();
        i0.ɵɵattribute("aria-orientation", "horizontal")("aria-valuenow", ctx.lastScrollLeft)("data-pc-section", "barx")("aria-controls", ctx.contentId);
        i0.ɵɵadvance(2);
        i0.ɵɵattribute("aria-orientation", "vertical")("aria-valuenow", ctx.lastScrollTop)("data-pc-section", "bary")("aria-controls", ctx.contentId);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgTemplateOutlet, i1.NgStyle, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollPanel, [{
    type: Component,
    args: [{
      selector: 'p-scroll-panel, p-scrollPanel, p-scrollpanel',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `
        <div #container [ngClass]="'p-scrollpanel p-component'" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'scrollpanel'">
            <div class="p-scrollpanel-content-container" [attr.data-pc-section]="'wrapper'">
                <div #content class="p-scrollpanel-content" [attr.data-pc-section]="'content'" (mouseenter)="moveBar()" (scroll)="onScroll($event)">
                    @if (!contentTemplate && !_contentTemplate) {
                        <ng-content></ng-content>
                    }
                    <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                </div>
            </div>
            <div
                #xBar
                class="p-scrollpanel-bar p-scrollpanel-bar-x"
                tabindex="0"
                role="scrollbar"
                [attr.aria-orientation]="'horizontal'"
                [attr.aria-valuenow]="lastScrollLeft"
                [attr.data-pc-section]="'barx'"
                [attr.aria-controls]="contentId"
                (mousedown)="onXBarMouseDown($event)"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp()"
                (focus)="onFocus($event)"
                (blur)="onBlur()"
            ></div>
            <div
                #yBar
                class="p-scrollpanel-bar p-scrollpanel-bar-y"
                tabindex="0"
                role="scrollbar"
                [attr.aria-orientation]="'vertical'"
                [attr.aria-valuenow]="lastScrollTop"
                [attr.data-pc-section]="'bary'"
                [attr.aria-controls]="contentId"
                (mousedown)="onYBarMouseDown($event)"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp()"
                (focus)="onFocus($event)"
            ></div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [ScrollPanelStyle]
    }]
  }], null, {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    step: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    contentViewChild: [{
      type: ViewChild,
      args: ['content']
    }],
    xBarViewChild: [{
      type: ViewChild,
      args: ['xBar']
    }],
    yBarViewChild: [{
      type: ViewChild,
      args: ['yBar']
    }],
    contentTemplate: [{
      type: ContentChild,
      args: ['content', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(ScrollPanel, {
    className: "ScrollPanel",
    filePath: "scrollpanel.ts",
    lineNumber: 80
  });
})();
class ScrollPanelModule {
  static ɵfac = function ScrollPanelModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ScrollPanelModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: ScrollPanelModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [ScrollPanel, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollPanelModule, [{
    type: NgModule,
    args: [{
      imports: [ScrollPanel, SharedModule],
      exports: [ScrollPanel, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ScrollPanelModule, {
    imports: [ScrollPanel, SharedModule],
    exports: [ScrollPanel, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ScrollPanel, ScrollPanelClasses, ScrollPanelModule, ScrollPanelStyle };
