import { IWeekDayTimeSlotDto } from "@GeneratedTsFiles/Availability/IWeekDayTimeSlotDto";
import moment from 'moment-timezone';
import { Injectable } from "@angular/core";
import { ITimeSlotDto } from "@GeneratedTsFiles/index";

@Injectable({
  providedIn: 'root'
})
export class AvailabilityTimeConverter {

  // Convert an array of ITimeslotExt to local time
  convertTimeSlotsToLocal(timeSlots: ITimeSlotDto[], timeZoneId: string): ITimeSlotDto[] {
    return timeSlots.map(slot => this.convertSingleTimeSlotToLocal(slot, timeZoneId));
  }

  // Convert a single ITimeslotExt to local time
  private convertSingleTimeSlotToLocal(timeSlot: ITimeSlotDto, timeZoneId: string): ITimeSlotDto {
    const startTimeLocal = this.convertTimeToLocal(timeSlot.startTime, timeZoneId);
    const endTimeLocal = this.convertTimeToLocal(timeSlot.endTime, timeZoneId);

    return {
      ...timeSlot,
      startTime: startTimeLocal,
      endTime: endTimeLocal
    };
  }

  // Convert an array of IDayOfTheWeekAvailabilityDto to local time
  convertDaysToLocal(days: IWeekDayTimeSlotDto[], timeZoneId: string): IWeekDayTimeSlotDto[] {
    return days.map(day => ({
      ...day,
      timeSlots: this.convertTimeSlotsToLocal(day.timeSlots, timeZoneId)
    }));
  }

  // Convert an array of ITimeslotExt to UTC
  convertTimeSlotsToUtc(timeSlots: ITimeSlotDto[], timeZoneId: string): ITimeSlotDto[] {
    return timeSlots.map(slot => this.convertSingleTimeSlotToUtc(slot, timeZoneId));
  }

  // Convert a single ITimeslotExt to UTC
  private convertSingleTimeSlotToUtc(timeSlot: ITimeSlotDto, timeZoneId: string): ITimeSlotDto {
    const startTimeUtc = this.convertTimeToUtc(timeSlot.startTime, timeZoneId);
    const endTimeUtc = this.convertTimeToUtc(timeSlot.endTime, timeZoneId);

    return {
      ...timeSlot,
      startTime: startTimeUtc,
      endTime: endTimeUtc
    };
  }

  // Function to convert a time to local time using the timezone ID// Convert a time to local time with proper date handling
  convertTimeToLocal(time: string | Date, timeZoneId: string): string {
    const parsedTime = typeof time === 'string' ? moment(time, 'HH:mm:ss').toDate() : time;
    return moment.tz(parsedTime, timeZoneId).format('HH:mm:ss');
  }

  // Convert a time to UTC with proper date handling
  convertTimeToUtc(time: string | Date, timeZoneId: string): string {
    const parsedTime = typeof time === 'string' ? moment(time, 'HH:mm:ss').toDate() : time;
    return moment.tz(parsedTime, timeZoneId).utc().format('HH:mm:ss');
  }

  // Convert an array of IDayOfTheWeekAvailabilityDto to UTC
  convertDaysToUtc(days: IWeekDayTimeSlotDto[], timeZoneId: string): IWeekDayTimeSlotDto[] {
    return days.map(day => ({
      ...day,
      timeSlots: this.convertTimeSlotsToUtc(day.timeSlots, timeZoneId)
    }));
  }
}