<div *ngFor="let dayKey of ['weekdays', 'weekends', 'other']">
  <h3 [ngClass]="compact ? 'hidden' : 'flex'"
      class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color">
    {{ dayKey | titlecase }}
  </h3>
  <div class="flex align-items-center justify-content-between mb-2">
    <div [ngClass]="compact ? '' : 'availability-border'" class="">
      <div class="flex align-items-center justify-content-center w-full">
        <div class="flex flex-nowrap sm:flex-wrap flex-column sm:flex-row gap-4 primary-purple-color">
          <!-- <span class="align-items-center line-height-1 flex text-xs font-semibold w-3rem">
              {{dayKey | titlecase}}
          </span> -->
          <div *ngFor="let time of timesByDay[dayKey]">
            <div class="flex align-items-center">
              <p-checkbox (onChange)="handleCheckboxChange($event, time)" [(ngModel)]="time.checked"
                          [binary]="true" [name]="time.time"
                          inputId="{{dayKey + time.time}}" styleClass="primary-availability-dropdown outlined"></p-checkbox>
              <label [for]="dayKey + time.time" class="ml-2 text-xs">{{ time.time | titlecase }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
