import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';

@Component({
    selector: 'app-student-availability-checkboxes',
    imports: [
        CommonModule,
        FormsModule,
        CheckboxModule,
    ],
    templateUrl: './student-availability-checkboxes.component.html',
    styleUrl: './student-availability-checkboxes.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentAvailabilityCheckboxesComponent {
  @Input() times: { time: string, flag: any, checked: boolean, day: string }[] = [];
  @Input() compact: boolean = false;
  @Input() selectedFlags: number | undefined;
  @Output() selectedFlagsChange = new EventEmitter<number>();
  @Output() hasAtLeastOneCheckboxChecked = new EventEmitter<boolean>();

  timesByDay: { [day: string]: { time: string, flag: any, checked: boolean, day: string }[] } = {};

  constructor() {
  }

  ngOnInit(): void {
    this.groupTimesByDay();
    this.preselectCheckboxes();
    this.hasAtLeastOneCheckboxChecked.emit(this.isAtLeastOneCheckboxChecked());
  }

  /**
   * Groups times array by day
   */
  private groupTimesByDay(): void {
    this.timesByDay = this.times.reduce((groups: {
      [day: string]: { time: string, flag: any, checked: boolean, day: string }[]
    }, item) => {
      const group = (groups[item.day] = groups[item.day] || []);
      group.push(item);
      return groups;
    }, {});
  }

  /**
   * Preselect checkboxes based on selectedFlags
   */
  private preselectCheckboxes(): void {
    this.times.forEach(time => {
      time.checked = (this.selectedFlags! & time.flag) === time.flag;
    });
  }

  /**
   * Handles checkbox change event
   * @param event The event object
   * @param time The time object
   */
  handleCheckboxChange(event: any, time: any): void {
    const isChecked = event.checked;

    this.updateSelectedFlags();
  }



  /**
   * Unchecks all times except the specified time
   * @param time The time to remain checked
   */
  private uncheckAllTimesExcept(time: any): void {
    this.times.forEach(t => t.checked = false);
    time.checked = true;
  }

  /**
   * Updates the selectedFlags based on checked times
   */
  private updateSelectedFlags(): void {
    this.selectedFlags = this.times.reduce((result, t) => {
      return t.checked ? result | t.flag : result;
    }, 0);

    this.emitSelectedFlags();
  }

  /**
   * Emits the selectedFlags to parent component
   */
  private emitSelectedFlags(): void {
    this.selectedFlagsChange.emit(this.selectedFlags);
    this.hasAtLeastOneCheckboxChecked.emit(this.isAtLeastOneCheckboxChecked());
  }

  /**
   * Checks if at least one checkbox is checked for weekdays, weekends, or other
   * @returns Whether at least one checkbox is checked
   */
  isAtLeastOneCheckboxChecked(): boolean {
    for (const dayKey of ['weekdays', 'weekends', 'other']) {
      const checked = this.timesByDay[dayKey] && this.timesByDay[dayKey].some(time => time.checked);
      if (checked) {
        return true;
      }
    }
    return false;
  }
}
