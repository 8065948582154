import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  computed,
  inject,
  input,
  signal
} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {IGetAllForParentResponse, IStudentBasicInfoDto} from '@GeneratedTsFiles/index';
import {CarouselModule, CarouselPageEvent} from 'primeng/carousel';
import {CheckboxModule} from 'primeng/checkbox';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {GeneralService} from 'src/app/core/services/general.service';
import {StudentGroupRowDisplayComponent} from '../student-group-row-display/student-group-row-display.component';

@Component({
    selector: 'app-image-box-group-select',
    imports: [
        CommonModule,
        CarouselModule,
        CheckboxModule,
        FormsModule,
        StudentGroupRowDisplayComponent,
    ],
    templateUrl: './image-box-group-select.component.html',
    styleUrl: './image-box-group-select.component.scss'
})
export class ImageBoxGroupSelectComponent {

  generalService = inject(GeneralService);
  @Input() selectionMode: 'single' | 'multiple' = 'single';
  @Input() nameProperty: string = 'name'; // Default image property name
  @Input() imageProperty: string = 'image'; // Default image property name
  @Input() groupImageProperty: string = 'image'; // Default image property name
  @Input() imageProperties: string[] = []; // Array of image property names
  @Input() itemImage: string = '';
  @Input() languagesMode = false;
  @Input() studentGroupMode = false;
  @Input() studentGroup = [] as IStudentBasicInfoDto[];
  @Input() items = [] as any[];
  @Input() createNewText!: string;
  @Input() createNewImage!: string;
  @Input() selectable = true;
  @Input() numVisible = 4;
  @Input() isItemSelected = false;
  @Input() selectedItem = ({} as any);
  selectedItemProperty = input('');
  baseProperty = input(''); // Default image property name
  textForNameProperty = input('');
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() newItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

  private untilDestroyed = untilDestroyed();
  private injector = inject(Injector);

  selectedItems = input([] as any[]);
  disabledItems = input([] as any[]);
  resetSelectionSignal = input(false);

  @Input() carouselIndex = 0;
  responsiveOptions!: { breakpoint: string; numVisible: number; numScroll: number; }[];
  selectedItemIndex: number = -1; // Track the index of the selected item

  // Maintain a Map of item selection statuses
  selectedItemsMap = new Map<any, boolean>();

  // Signals for reactive properties
  selectedItemsSignal = computed(() => this.selectedItems());

  disabledItemsSignal = computed(() => this.disabledItems());

  constructor() {
  }

  // Method to handle item selection
  selectItem(item: { studentBasicInfo: any; disabled: any; }) {
    this.toggleSelection(item);
    this.selectedItem = (item); // Update selected item signal

    this.itemClicked.emit(item);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateNumVisible();
  }

  private updateNumVisible() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      this.numVisible = this.numVisible;
    } else if (screenWidth >= 992) {
      this.numVisible = 3;
    } else if (screenWidth >= 768) {
      this.numVisible = 1;
    } else {
      this.numVisible = 1;
    }
  }

  ngOnInit() {
    console.log(this.items);
    this.setupItems();
    this.setupResponsiveOptions();
    this.updateNumVisible();

    if (this.createNewText && this.createNewImage) {
      this.createNewItem();
    }

    if (this.selectionMode === 'multiple') {
      this.initializeSelectedItems();
    }
    this.initializeDisabledItems();

    toObservable(this.selectedItemsSignal, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (selectedItems) => {
        if (!selectedItems) {
          return;
        }

        if (!this.items) {
          return;
        }
        this.items.forEach((item, keya) => {

          this.selectedItemsMap.forEach((value, key) => {
            // Check if the objects are deeply equal
            if (this.objectsAreEqual(key, item)) {
              this.selectedItemsMap.set(key, value); // Toggle the current item
            }
          });

        });

      }
    });

    toObservable(this.resetSelectionSignal, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (reset) => {
        if (reset) {
          console.log('resetSelectionSignal', reset);
          this.selectedItemsMap.clear();
        }
      }
    });
  }

  ngOnDestroy() {
    this.untilDestroyed();
    this.selectedItemsMap.clear();
  }

  toggleSelection(item: { studentBasicInfo: any; disabled: any; }) {
    if (item.disabled) {
      return;
    }
    if (this.selectionMode === 'single') {
      this.selectSingle(item);
    } else {
      this.toggleMultiple(item);
    }
  }

  createNewItem() {
    const newItem = {
      name: this.createNewText,
      image: this.createNewImage,
      selected: false,
      new: true,
    };
    this.items.unshift(newItem);
  }

  isSelected(item: { studentBasicInfo: IStudentBasicInfoDto; disabled: boolean; }): boolean {
    // Check if the item is the currently selected item
    if (this.selectedItem && this.objectsAreEqual(this.selectedItem, item)) {
      return true;
    }

    // Check if the item is in the selectedItemsMap
    return this.selectedItemsMap.get(item) || false;
  }

  isDisabled(item: { studentBasicInfo: IStudentBasicInfoDto; disabled: boolean; }): boolean {
    return item.disabled;
  }

  onCarouselIndexChange(event: CarouselPageEvent) {
    this.carouselIndex = event.page as number;
    this.pageChanged.emit(this.carouselIndex);
  }

  private setupItems() {
    if (!this.items) {
      return;
    }
    this.selectedItemsMap.clear();
  }

  private initializeSelectedItems() {
    if (!this.items) {
      return;
    }
    this.selectedItemsMap.clear();
    this.items.forEach(item => {
      const isSelected = this.selectedItems().some(selected => selected.id === item.id);
      this.selectedItemsMap.set(item, isSelected);
    });
  }

  private initializeDisabledItems() {
    this.items.forEach(item => {
      item.disabled = this.disabledItems().some(disabled => disabled.id === item.id);
    });

    console.log(this.items);
  }

  private setupResponsiveOptions() {
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: this.numVisible,
        numScroll: this.numVisible
      },
      {
        breakpoint: '991px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  private selectSingle(item: any) {
    this.selectedItemsMap.clear(); // Clear previous selections
    let found = false;
    this.selectedItemsMap.forEach((value, key) => {
      // Check if the objects are deeply equal
      if (this.objectsAreEqual(key, item)) {
        this.selectedItemsMap.set(key, !value); // Toggle the current item
        found = true;
      } else {
        this.selectedItemsMap.set(key, false); // Deselect other items
      }
    });

    // If the item was not found in selectedItemsMap, add it
    if (!found) {
      this.selectedItemsMap.set(item, true);
    }
    console.log(this.selectedItemsMap);
    // Emit the appropriate events
    if (item.new && item.new === true) {
      this.newItemClicked.emit();
    } else {
      this.itemClicked.emit(item);
    }
  }

  private toggleMultiple(item: any) {
    let found = false;
    this.selectedItemsMap.forEach((value, key) => {
      if (this.objectsAreEqual(key, item)) {
        this.selectedItemsMap.set(key, !value);
        found = true;
      }
    });
    if (!found) {
      this.selectedItemsMap.set(item, true);
    }
    this.itemClicked.emit(item);
  }

  private objectsAreEqual(obj1: any, obj2: any): boolean {
    // Implement your equality comparison logic here
    // Example: Compare all keys of the objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  prepareNameProperty(item: any) {
    if (this.studentGroupMode) {
      return this.generalService.getGroupDetails(item);
    }
    if (this.baseProperty()) {
      const baseProperty = this.baseProperty() as string;
      return item[baseProperty][this.nameProperty] + this.textForNameProperty();
    } else {
      return item[this.nameProperty] + this.textForNameProperty();
    }
  }

  getStudentGroupDetails(item: any) {
    return (item);
  }

}
