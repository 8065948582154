<div class="px-3">
  <!-- Language Selection -->
  <div [ngClass]="{ 'pointer-events-none': editMode(), 'opacity-50': editMode() }" class="p-1 mt-3">
    <h3 class="primary-purple-color m-0 text-center mb-2">Choose Language</h3>

    <app-image-box-group-select (itemClicked)="onLanguageSelected($event)" [items]="teachingLanguages$()" [languagesMode]="true"
                                [selectedItemProperty]="'id'" [selectedItem]="selectedTeachingLanguage"
                                mode="languages">
    </app-image-box-group-select>
  </div>

  <!-- Level Selection -->
  <div [ngClass]="{ 'pointer-events-none': editMode(), 'opacity-50': editMode() }" class="p-1 mt-2">
    <h3 class="primary-purple-color m-0 text-center mb-2">Choose Level</h3>

    <app-image-box-group-select (itemClicked)="onLevelSelected($event)" [imageProperty]="''"
                                [items]="studentLevels$()" [selectedItemProperty]="'code'"
                                [selectedItem]="selectedLanguageLevel$()">
    </app-image-box-group-select>

    <app-form-field-validation-message *ngIf="areAllStudentLevelsDisabled()" [severity]="Severity.Info"
                                       [text]="'There are no assigned levels found for your students. Please wait for teacher to evaluate your students.'"
                                       styleClass="w-full justify-content-center my-3">
    </app-form-field-validation-message>
  </div>

  <!-- Student Selection -->
  <div class="p-1 mt-2">
    <h3 class="primary-purple-color m-0 text-center mb-2">Choose Student</h3>

    <div *ngIf="selectedTeachingLanguage !== null && selectedLanguageLevel$() !== null">
      <app-form-field-validation-message *ngIf="filteredStudents === null" [severity]="Severity.Warning"
                                         [text]="'You need at least two registered students to create a group.'"
                                         styleClass="w-full justify-content-center my-3">
      </app-form-field-validation-message>

      <div *ngIf="filteredStudents !== null && selectedTeachingLanguage !== null" class="">
        <p-carousel (onPage)="carouselService.onCarouselIndexChange($event)" [circular]="false"
                    [numScroll]="carouselService.numVisible" [numVisible]="carouselService.numVisible"
                    [page]="carouselService.carouselIndex" [responsiveOptions]="carouselService.responsiveOptions"
                    [showIndicators]="false" [showNavigators]="filteredStudents.length > carouselService.numVisible"
                    [value]="filteredStudents">
          <ng-template let-index="index" let-item pTemplate="item">
            <app-image-box-group-item (itemClicked)="onStudentSelected($event)"
                                      [isDisabled]="item.disabled" [isSelected]="item.selected" [item]="item"
                                      [nameProperty]="'firstName'" [removeable]="true"
                                      [selectable]="true" selectionMode="multiple">
            </app-image-box-group-item>
          </ng-template>
        </p-carousel>
      </div>
    </div>

    <app-form-field-validation-message
      *ngIf="(selectedTeachingLanguage$() === null || selectedLanguageLevel$() === null) && !editMode()"
      [severity]="Severity.Info" [text]="'You need to choose language and level to proceed.'"
      styleClass="w-full justify-content-center my-3">
    </app-form-field-validation-message>
  </div>

  <div class="mt-2">

    <!-- Validation Messages -->
    <ng-container>
      <ng-container *ngFor="let rule of ruleMessageDisplay()">
        <app-form-field-validation-message [severity]="rule.type"
                                           [text]="rule.message" styleClass="w-full justify-content-center mb-2">
        </app-form-field-validation-message>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="editMode() && editGroupState() === EditGroupState.EditMembers">
      <div class="flex flex-column align-items-center justify-content-center w-full text-center gap-3 mb-2">
        <p-button (click)="editGroupSave()" [disabled]="shouldDisableEditSaveChangesButton()" [icon]="'pi pi-plus'" [outlined]="true"
                  [rounded]="true" label="Save Group Changes" severity="primary"
                  styleClass="w-16rem"/>
      </div>
    </ng-container>

    <div *ngIf="!editMode()" class="flex align-items-center justify-content-center w-full text-center  mb-2">
      <p-button (onClick)="createNewGroup()" [disabled]="shouldDisableCreateButton()" [loading]="createStudentGroup$().loading"
                [rounded]="true" class="w-full" styleClass="submit-btn w-full" icon="pi pi-chevron-right" iconPos="right"
                label="Proceed"
                role="button" type="submit">
      </p-button>
    </div>

  </div>
